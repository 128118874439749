.counter {
  align-items: center;
  border-radius: var(--radius-global-radius-m);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-width: var(--space-size-medium);
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
  position: relative;
}

.counter .number {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.counter.white {
  background-color: var(--colors-background-default);
}

.counter.stroke {
  border: 1px solid;
}

.counter.high.green {
  background-color: var(--colors-background-positive-strong);
}

.counter.low.stroke {
  border-color: var(--colors-border-neutral-subtle);
}

.counter.high.orange {
  background-color: var(--colors-background-warning-strong);
}

.counter.high.stroke {
  border-color: var(--colors-border-neutral-strong);
}

.counter.low.green {
  background-color: var(--colors-background-positive-on-subtle);
}

.counter.low.orange {
  background-color: var(--colors-background-warning-on-subtle);
}

.counter.low.red {
  background-color: var(--colors-background-negative-on-subtle);
}

.counter.grey.high {
  background-color: var(--colors-background-neutral-strong);
}

.counter.high.brand {
  background-color: var(--colors-background-brand-strong);
}

.counter.low.blue {
  background-color: var(--colors-background-informative-on-subtle);
}

.counter.high.blue {
  background-color: var(--colors-background-informative-strong);
}

.counter.low.grey {
  background-color: var(--colors-background-neutral-on-subtle);
}

.counter.red.high {
  background-color: var(--colors-background-negative-strong);
}

.counter.low.brand {
  background-color: var(--colors-background-brand-on-subtle);
}

.counter.stroke .number {
  color: var(--colors-content-primary);
}

.counter.high.green .number {
  color: var(--colors-content-inverted-primary);
}

.counter.high.orange .number {
  color: var(--colors-content-inverted-primary);
}

.counter.low.green .number {
  color: var(--colors-content-primary);
}

.counter.low.orange .number {
  color: var(--colors-content-primary);
}

.counter.low.red .number {
  color: var(--colors-content-primary);
}

.counter.high.white .number {
  color: var(--colors-content-primary);
}

.counter.grey.high .number {
  color: var(--colors-content-inverted-primary);
}

.counter.high.brand .number {
  color: var(--colors-content-inverted-primary);
}

.counter.low.blue .number {
  color: var(--colors-content-primary);
}

.counter.high.blue .number {
  color: var(--colors-content-inverted-primary);
}

.counter.low.grey .number {
  color: var(--colors-content-primary);
}

.counter.low.white .number {
  color: var(--colors-content-tertiary);
}

.counter.red.high .number {
  color: var(--colors-content-inverted-primary);
}

.counter.low.brand .number {
  color: var(--colors-content-primary);
}
