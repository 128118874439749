.status {
  align-items: center;
  border-radius: var(--radius-global-radius-m);
  display: inline-flex;
  height: var(--space-size-medium);
  justify-content: center;
  position: relative;
  width: 100px;
}
.status-margin-fix {
  padding-left: var(--space-space-small);
}
.status .dot {
  border-radius: 3px;
  height: 6px;
  position: relative;
  width: 6px;
}

.status .label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.status.low {
  gap: var(--space-space-xsmall);
}

.status.medium {
  gap: var(--space-space-2xsmall);
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.status.strong {
  gap: var(--space-space-2xsmall);
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.status.negative.strong {
  background-color: var(--colors-background-negative-strong);
}

.status.brand.strong {
  background-color: var(--colors-background-brand-strong);
}

.status.brand.medium {
  background-color: var(--colors-background-brand-muted);
}

.status.informative.strong {
  background-color: var(--colors-background-informative-strong);
}

.status.medium.warning {
  background-color: var(--colors-background-warning-muted);
}

.status.medium.informative {
  background-color: var(--colors-background-informative-muted);
}

.status.positive.medium {
  background-color: var(--colors-background-positive-muted);
}

.status.negative.medium {
  background-color: var(--colors-background-negative-muted);
}

.status.positive.strong {
  background-color: var(--colors-background-positive-strong);
}

.status.neutral.strong {
  background-color: var(--colors-background-neutral-strong);
}

.status.neutral.medium {
  background-color: var(--colors-background-neutral-muted);
}

.status.warning.strong {
  background-color: var(--colors-background-warning-strong);
}

.status.strong .dot {
  background-color: var(--colors-content-inverted-primary);
}

.status.low.neutral .dot {
  background-color: var(--colors-content-primary);
}

.status.low.positive .dot {
  background-color: var(--colors-content-positive-primary);
}

.status.brand.medium .dot {
  background-color: var(--colors-content-brand-primary);
}

.status.medium.warning .dot {
  background-color: var(--colors-content-warning-primary);
}

.status.medium.informative .dot {
  background-color: var(--colors-content-informative-primary);
}

.status.low.informative .dot {
  background-color: var(--colors-content-informative-primary);
}

.status.positive.medium .dot {
  background-color: var(--colors-content-positive-primary);
}

.status.negative.medium .dot {
  background-color: var(--colors-content-negative-primary);
}

.status.low.warning .dot {
  background-color: var(--colors-content-warning-primary);
}

.status.neutral.medium .dot {
  background-color: var(--colors-content-primary);
}

.status.low.negative .dot {
  background-color: var(--colors-content-negative-primary);
}

.status.low.brand .dot {
  background-color: var(--colors-content-brand-primary);
}

.status.strong .label {
  color: var(--colors-content-inverted-primary);
}

.status.low.neutral .label {
  color: var(--colors-content-primary);
}

.status.low.positive .label {
  color: var(--colors-content-positive-primary);
}

.status.brand.medium .label {
  color: var(--colors-content-brand-primary);
}

.status.medium.warning .label {
  color: var(--colors-content-warning-primary);
}

.status.medium.informative .label {
  color: var(--colors-content-informative-primary);
}

.status.low.informative .label {
  color: var(--colors-content-informative-primary);
}

.status.positive.medium .label {
  color: var(--colors-content-positive-primary);
}

.status.negative.medium .label {
  color: var(--colors-content-negative-primary);
}

.status.low.warning .label {
  color: var(--colors-content-warning-primary);
}

.status.neutral.medium .label {
  color: var(--colors-content-primary);
}

.status.low.negative .label {
  color: var(--colors-content-negative-primary);
}

.status.low.brand .label {
  color: var(--colors-content-brand-primary);
}
