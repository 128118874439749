.tabheader {
  color: var(  --colors-content-primary);
  font-family: var(--body-strong-medium-font-family);
  font-size: var(--body-strong-medium-font-family);
  font-style: var(--body-strong-medium-font-family);
  font-weight: var(--body-strong-medium-font-family);
  letter-spacing: var(--body-strong-medium-font-family);
  line-height: var(--body-strong-medium-font-family);
}
.tabcontent {
  color: var(  --colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-family);
  font-style: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-family);
  letter-spacing: var(--body-normal-medium-font-family);
  line-height: var(--body-normal-medium-font-family);
}