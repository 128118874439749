.input-wrapper {
  position: relative;
  color: var(--colors-content-secondary);
  flex: 1;
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  
  
}

.input {
  width: 100% !important;
  box-sizing: border-box;
}
.input-wrapper input {
  background-color: var(--colors-action-formfield-normal);
  border: 1px solid;
  border-color: var(--colors-action-neutral-subtle-normal);
  border-radius: var(--radius-global-radius-m);
  display: flex;
  gap: var(--space-space-2xsmall);
  height: var(--space-size-xlarge);
  color: var(--colors-content-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
 
  
}

.input-wrapper input::placeholder {
  color: var(--colors-content-tertiary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.icon {
  width: 20px; /* Définissez la largeur de l'icône */
  height: 20px; /* Définissez la hauteur de l'icône */
}


.input-wrapper .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input-wrapper .icon.before {
  left: var(--space-space-2xsmall);
}

.input-wrapper .icon.after {
  right: var(--space-space-2xsmall);
}


/* Styles pour l'état hover */
.input-wrapper:hover input {
  background-color: var(--colors-action-formfield-hover);
  border: 1px solid var(--colors-action-neutral-subtle-hover); 
  color: var(--colors-content-primary);
}

/* Styles par défaut */
/* Styles par défaut pour l'input */
.input-wrapper input {
  transition: border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s; /* Transition pour les changements de couleur de bordure et de fond */
}

/* Styles pour l'état focus */
.input-wrapper input:focus {
  
  outline: none; /* Supprimer le liseré bleu autour de l'input lorsqu'en focus */
  box-shadow: 0px 0px 0px 3.5px rgba(0, 162, 175, 0.2), 0px 0px 0px 1px rgba(0, 162, 175, 1);
}

.input-wrapper .suffix {
  position: absolute;
  top: 50%;
  right: var(--space-space-xsmall); /* ou toute autre valeur appropriée */
  transform: translateY(-50%);
}

.input-wrapper {
  position: relative; /* Déplacez la position relative ici */
}



.input-container.with-icons input {
  padding-left: var(--space-space-large);
  padding-right: var(--space-space-large); /*ajuster en fonction de la longueur du suffix*/
}

.input-container.with-icon-before input {
  padding-left: var(--space-space-large);
  padding-right: var(--space-space-xsmall); /*ajuster en fonction de la longueur du suffix*/
}

.input-container.with-icon-after input {
  padding-left: var(--space-space-xsmall);
  padding-right: var(--space-space-large); /*ajuster en fonction de la longueur du suffix*/
}

.input-container.without-icons input {
  padding-left: var(--space-space-xsmall);
  padding-right: var(--space-space-xsmall); /*ajuster en fonction de la longueur du suffix*/
}

.react-datepicker-wrapper {
  display: inline !important;
}



/* DatePicker */
.react-datepicker-popper {
  margin-top: 3px;
  margin-bottom: 3px;
  z-index: 999 !important;
}
.react-datepicker__triangle {
  fill: var(--colors-background-neutral-subtle)!important;
  color: var(--colors-background-neutral-subtle)!important;
  stroke: var(--colors-border-default)!important;
}

.react-datepicker{ /* ok Global*/
  border-radius: var(--radius-global-radius-m)!important;
  overflow: hidden!important;
  border: 1px solid!important;
  border-color: var(--colors-border-default)!important;
  box-shadow: var(--2)!important;
  z-index: 999 !important;
}

.react-datepicker__header { /* ok*/
  gap: var(--space-space-2xsmall)!important;
  padding: var(--space-space-small) var(--space-space-small) var(--space-space-2xsmall) var(--space-space-small)!important;
  background-color: var(--colors-background-neutral-subtle)!important;
  border-top-style: none!important;
  border-right-style: none!important;
  border-bottom-width: 1px!important;
  border-bottom-style: solid!important;
  border-left-style: none!important;
  border-color: var(--colors-border-default)!important;
}

.react-datepicker__current-month { /* OK Mois top*/
  font-family: var(--header-small-font-family)!important;
  font-weight: var(--header-small-font-weight)!important;
  color: var(--colors-content-primary)!important;
  font-size: var(--header-small-font-size)!important;
  text-align: center!important;
  letter-spacing: var(--header-small-letter-spacing)!important;
  line-height: var(--header-small-line-height)!important;
  font-style: var(--header-small-font-style)!important;
}

.react-datepicker__day-name { /* ok  lundi mardi... */
  padding-top: var(--space-space-2xsmall)!important; 
  gap: var(--space-space-3xsmall)!important;
  font-family: var(--body-normal-medium-font-family)!important;
  font-weight: var(--body-normal-medium-font-weight)!important;
  color: var(--colors-content-secondary)!important;
  font-size: var(--body-normal-medium-font-size)!important;
  letter-spacing: var(--body-normal-medium-letter-spacing)!important;
  line-height: var(--body-normal-medium-line-height)!important;
  font-style: var(--body-normal-medium-font-style)!important;
  width: var(--space-size-large)!important;
  height: var(--space-size-large)!important;
  display: flex inline!important;
  justify-content: center!important;
  align-items: center!important;
}

.react-datepicker__month-container{ /* ok  fond du mois */
  background-color: var(--colors-background-default); 
  border-radius: var(--radius-global-radius-m)!important;
  
}

.react-datepicker__day { /* ok  jours */
  background-color: var(--colors-background-default)!important;
  border-radius: var(--radius-global-radius-round)!important;
  font-family: var(--body-normal-medium-font-family) !important;
  font-size: var(--body-normal-medium-font-size)!important;
  font-style: var(--body-normal-medium-font-style)!important;
  font-weight: var(--body-normal-medium-font-weight)!important;
  letter-spacing: var(--body-normal-medium-letter-spacing)!important;
  color: var(--colors-content-secondary) !important;
  width: var(--space-size-large)!important;
  height: var(--space-size-large)!important;
  display: flex inline!important;
  justify-content: center!important;
  align-items: center!important;
}



.react-datepicker__day:hover { /* ok  jours hover */
  background-color: var(--colors-background-neutral-on-subtle)!important;
  border-radius: var(--radius-global-radius-round) !important;
  color: var(--colors-content-secondary)!important;
}

.react-datepicker__day--outside-month {
  background-color: var(--colors-background-default);
  border-radius: 999px;
  font-family: var(--body-normal-medium-font-family) !important;
  font-size: var(--body-normal-medium-font-size)!important;
  font-style: var(--body-normal-medium-font-style)!important;
  font-weight: var(--body-normal-medium-font-weight)!important;
  letter-spacing: var(--body-normal-medium-letter-spacing)!important;
  color: var(--colors-content-disabled) !important;
  
}

.react-datepicker__day--selected {
  background-color: var(--colors-background-brand-strong)!important;
  border-radius: var(--radius-global-radius-round) !important;
  color: var(--colors-content-inverted-primary)!important;
  font-style: var(--body-strong-medium-font-style)!important;
}

.react-datepicker__day--selected:hover{
  background-color: var(--colors-background-brand-strong)!important;
  border-radius: var(--radius-global-radius-round) !important;
  color: var(--colors-content-inverted-primary)!important;
  font-style: var(--body-strong-medium-font-style)!important;
}

.react-datepicker__day--today {
  border: 1px solid!important;
  border-color: var(--colors-border-brand-strong)!important;
  font-family: var(--body-normal-medium-font-family)!important;
  font-weight: var(--body-normal-medium-font-weight)!important;
  font-size: var(--body-normal-medium-font-size)!important;
  letter-spacing: var(--body-normal-medium-letter-spacing)!important;
  font-style: var(--body-normal-medium-font-style)!important;
}

.react-datepicker__day--today:hover {
  background-color: var(--colors-background-brand-subtle)!important;
  border: 1px solid!important;
  border-color: var(--colors-border-brand-strong)!important;
  font-family: var(--body-normal-medium-font-family)!important;
  font-weight: var(--body-normal-medium-font-weight)!important;
  color: var(--colors-background-brand-strong)!important;
  font-size: var(--body-normal-medium-font-size)!important;
  letter-spacing: var(--body-normal-medium-letter-spacing)!important;
  font-style: var(--body-normal-medium-font-style)!important;
}

.react-datepicker__navigation{
  top:12px !important;
  fill: var(--colors-content-tertiary);
}

.react-datepicker__navigation--previous{
  left:16px !important;
}

.react-datepicker__navigation--next{
  right:16px !important;
}


.react-datepicker__navigation-icon::before {
  
  border-width: 2px 2px 0 0!important;
  display: block;
  height: 8px!important;
  width: 8px!important;
}

.react-datepicker__navigation-icon:hover {
  border-color: var(--colors-content-secondary) !important;

}

/*input validation*/
.input-error {
  outline: none;
  border: 0.5px solid var(--colors-action-negative-normal);
  border-radius: var(--radius-global-radius-m);
 
}

.input-error:focus {
  border: 1px solid var(--colors-semantic-negative-negative-900);
}