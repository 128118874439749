.radio-screen {
  align-items: flex-start;
  background-color: var(--colors-background-neutral-surface);
  display: flex;
  flex-direction: column;
  gap: var(--space-space-large);
  padding: var(--space-space-large);
  position: relative;
}

.radio-screen .text-wrapper-2 {
  font-family: var(--header-large-font-family);
  font-size: var(--header-large-font-size);
  font-style: var(--header-large-font-style);
  font-weight: var(--header-large-font-weight);
  letter-spacing: var(--header-large-letter-spacing);
  line-height: var(--header-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radio-screen .content {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--colors-background-default);
  border-radius: var(--radius-global-radius-l);
  box-shadow: var(--1);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 900px;
}

.radio-screen .cont {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px;
  position: relative;
  width: 100%;
}

.radio-screen .radio-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.radio-screen .radio-instance {
  position: relative !important;
}

.radio-screen .text-wrapper-3 {
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


/*CSS pour le select */

.custom-select {
  position: relative;
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  z-index: 10;
}
.select-active {
  
  transition: border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s;
}
.select-active {
  border-radius: var(--radius-global-radius-m);
  box-shadow: 0px 0px 0px 3.5px rgba(0, 162, 175, 0.2), 0px 0px 0px 1px rgba(0, 162, 175, 1);
}

.custom-select select {
  
  display: none; /*hide original SELECT element: */
}

.select-selected {
  display: flex;
  align-items: center;
  background-color: var(--colors-action-formfield-normal);
  border-radius: var(--radius-global-radius-m);
  border: 1px solid var(--colors-action-neutral-subtle-normal); 
  gap: var(--space-space-2xsmall);
  height: var(--space-size-xlarge); 
  padding: 0px var(--space-space-large) 0px var(--space-space-small);
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.select-selected:hover {
  transition: border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s;
}

.select-selected:hover {
  background-color: var(--colors-action-formfield-hover);
  border: 1px solid var(--colors-action-neutral-subtle-hover); 
  color: var(--colors-content-primary);
}
.custom-select.placeholder .select-selected {
  color: var(--colors-content-tertiary);
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 16px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: var(--colors-action-neutral-normal) transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent var(--colors-action-neutral-hover) transparent;
  top: 10px;
}

.select-items div {

  border: 1px solid transparent; 
  border-color: transparent transparent var(--colors-action-neutral-subtle-normal) transparent;
  cursor: pointer;
  height: var(--space-size-xlarge);
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
  display: flex;
  align-items: center;
}

.select-items div,.select-selected {
  color: var(--colors-content-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  cursor: pointer;
}

.select-items div,.select-selected :hover{
  color: var(--colors-content-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  cursor: pointer;
}

.select-items div,.select-selected :default{
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  cursor: pointer;
}

.select-items {
  margin-top: 2px;
  border-radius: var(--radius-global-radius-m);
  box-shadow: var(--2);
  border: 1px solid;
  border-color: var(--colors-action-neutral-subtle-normal);
  position: absolute;
  background-color: var(--colors-background-default);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 90;
  margin-left: -2px; 
  margin-right: -2px;
  /* Tentative de fix du scroll */
  max-height: 400px; 
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* for Firefox */
}
.select-items::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.select-hide {
  display: none;
}

.select-items div.selected,
.same-as-selected {
  background-color: var(--colors-action-neutral-subtle-hover); /* Assurez-vous que cette ligne est correcte */
  color: var(--colors-content-primary);
}

.select-items div:hover, .same-as-selected {
  background-color: var(--colors-action-formfield-hover);
  color: var(--colors-content-primary);
  border-color: transparent transparent var(--colors-action-neutral-subtle-hover) transparent;
  
}


.select-items div:first-child {
  border-radius:var(--radius-global-radius-m) var(--radius-global-radius-m) 0 0 ;
}

.select-items div:last-child {
  border-bottom: none;
  border-radius: 0 0 var(--radius-global-radius-m) var(--radius-global-radius-m);
}


