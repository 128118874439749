.tasks {
    align-items: center;
    border-radius: var(--radius-global-radius-m);
    display: flex;
    gap: var(--space-space-2xsmall);
    height: var(--space-size-2xlarge);
    padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--colors-action-neutral-subtle-normal);
    margin-bottom: var(--space-space-2xsmall);
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
  
  }
  
  .tasks .checkbox-instance {
    position: relative !important;
  }
  
  .tasks .label {
    color: var(--colors-content-primary);
    flex: 1;
    font-family: var(--body-normal-medium-font-family);
    font-size: var(--body-normal-medium-font-size);
    font-style: var(--body-normal-medium-font-style);
    font-weight: var(--body-normal-medium-font-weight);
    letter-spacing: var(--body-normal-medium-letter-spacing);
    line-height: var(--body-normal-medium-line-height);
    position: relative;
    
  }
  
  .tasks.is-hover-false.is-checked-false {
    background-color: var(--colors-action-neutral-subtle-normal);
  }
  
  .tasks.is-checked-true.is-hover-false {
    background-color: var(--colors-action-positive-subtle-active);
  }
  
  .tasks.is-hover-true.is-checked-false {
    background-color: var(--colors-action-neutral-subtle-hover);
  }
  
  .tasks.is-checked-true.is-hover-true {
    background-color: var(--colors-action-positive-subtle-hover);
  }
  
  .task-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .task-item input[type="checkbox"] {
    margin-right: var(--space-space-2xsmall);
  }
  .task-completed {
    background-color: var(--colors-action-positive-subtle-active);
  }
  .tasks:hover:not(.task-completed) {
    background-color: var(--colors-action-neutral-subtle-hover);
  }
  .tasks:last-child {
    margin-bottom: 0; /* Remove margin-bottom for the last child */
  }