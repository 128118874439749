.App {
  text-align: left;
}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased; /* Improve font rendering on Safari */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
}
form {
  width: 100%;
}
.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.underlined{
  cursor: pointer;
  text-decoration: underline;
  color: var(--colors-content-informative-primary);
  font-family: var(--body-underline-large-font-family);
  font-size: var(--body-underline-large-font-size);
  font-style: var(--body-underline-large-font-style);
  font-weight: var(--body-underline-large-weight);
}
.App-header {
  background-color: #4c8185;
  min-height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.full-width {
  width: 100%;
}
.container {
  display: flex;
  background-color: var(--colors-background-neutral-surface);
  border-radius: var(--radius-global-radius-l);
  height: 100vh;
  
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Aligns children to the start and end of the container */
  height: 100vh !important;
  /* Full viewport height */
  width: 218px;
  min-width: 218px;
  background-color: var(--colors-background-default);
  border-radius: var(--radius-global-radius-l) var(--radius-global-radius-null) var(--radius-global-radius-null) var(--radius-global-radius-l);
  box-shadow: var(--2);
  
}

.side-nav-container {
  padding: var(--space-space-large) var(--space-space-small) var(--space-space-large) var(--space-space-small);
  gap: var(--space-space-2xsmall);
}

.user-info-container {
  margin-top: auto;
  /* Pushes SideNavItemUserInfo to the bottom */
}


.main-content-regular {
  background-color: white;
  border-radius: var(--radius-global-radius-l);
}

.columns-container {
  display: flex;
  justify-content: space-between;
}

.columnL {
  flex: 0 1 33%;
  gap: 2px;
  /* Adjust the width as needed */

}

.columnR {
  padding-left: var(--space-space-small);
  flex: 0 1 66%;
  /* Adjust the width as needed */

}

.columnR-alt {
  padding-left: var(--space-space-small);
  flex: 0 1 33%;
  gap: 2px;
  /* Adjust the width as needed */

}

.columnL-alt {
  
  flex: 0 1 66%;
  /* Adjust the width as needed */

}
.content {
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  padding: var(--space-space-large);
  box-sizing: border-box;
}

.boxed {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display:grid;
  
}
li {
  height: min-content;
  vertical-align: middle;
  align-self: center;
}
/*SIDE NAV*/
.side-nav-screen {
  align-items: flex-start;
  background-color: var(--colors-background-neutral-surface);
  display: flex;
  flex-direction: column;
  gap: var(--space-space-large);
  padding: var(--space-space-large);
  position: relative;
}

.side-nav-screen .div {
  color: var(--colors-content-primary);
  font-family: var(--header-large-font-family);
  font-size: var(--header-large-font-size);
  font-style: var(--header-large-font-style);
  font-weight: var(--header-large-font-weight);
  letter-spacing: var(--header-large-letter-spacing);
  line-height: var(--header-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.side-nav-screen .side-nav-instance {
  flex: 0 0 auto !important;
}

.side-nav-screen .icon-instance-node {
  height: var(--space-size-small) !important;
  position: relative !important;
  width: var(--space-size-small) !important;
}

/* RFP Cards */
.card-component {
  /*display: flex;*/
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--colors-background-default);
  border-radius: var(--radius-global-radius-l);
  box-shadow: var(--1);
  width: 100%;
  margin-bottom: var(--space-size-small);
}
.align-right {
  justify-self: flex-end !important;
  align-content: flex-end;
}
.align-left {
  justify-self: flex-start !important;
  align-content: baseline;
}

.card-header {
  display: flex;
  height: var(--space-size-4xlarge);
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: var(--colors-border-default);
}

.card-header-alt {
  display: flex;
  height: var(--space-size-4xlarge);
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: var(--colors-border-default);
}
.card-header>div {
  padding: var(--space-space-small);
  display: flex;
  align-items: center;
}
.card-header-alt>div {
  display: flex;
  align-items: center;
 
}
.card-content {
  display: flex;
  padding: 0 var(--space-space-small) ;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}
.card-content-v-padding {
  padding-top: var(--space-space-small);
  padding-bottom: var(--space-space-small);
}
.card-header-buttons {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  gap: var(--space-space-2xsmall);
  
}
.card-title {
  font-family: var(--header-small-font-family);
  font-size: var(--header-small-font-size);
  font-style: var(--header-small-font-style);
  font-weight: var(--header-small-font-weight);
  letter-spacing: var(--header-small-spacing);
  line-height: var(--header-small-line-height);
  color: var(--colors-content-secondary);
  margin-left: 10px;
}

.card-maincontent {
  white-space: pre-wrap;
  width: 100%;
  color: var(--colors-content-primary);
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
  
}

.card-label {
  color: var(--colors-content-tertiary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.card-warningtxt {
  color: var(--colors-content-negative-primary);
}

.header-xsmall {
  font-family: var(--header-xsmall-font-family);
  font-size: var(--header-xsmall-font-size);
  font-style: var(--header-xsmall-font-style);
  font-weight: var(--header-xsmall-font-weight);
  letter-spacing: var(--header-xsmall-spacing);
  line-height: var(--header-xsmall-line-height);
  color: var(--colors-content-primary);
}

/*INPUTS*/
.input-group {

  display: flex;
  align-items: center;
  position: relative;
}

.input-normal {
  width: calc(100% - var(--space-space-small) * 2);
  height: var(--space-size-xlarge);
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
  border-radius: var(--radius-global-radius-m);
  background-color: var(--colors-action-formfield-normal);
  border-color: var(--colors-border-neutral-subtle);
  border: 1px solid var(--colors-border-neutral-subtle);
}

.input-normal:disabled {
  background-color: transparent;
}

.input-w-suffix {
  width: 100%;
  height: var(--space-size-xlarge);
  padding: 0px 0px 0px var(--space-space-small);
  border-radius: var(--radius-global-radius-m) 0 0 var(--radius-global-radius-m);
  background-color: var(--colors-action-formfield-normal);
  border-color: var(--colors-border-neutral-subtle);
  border-left: 1px solid var(--colors-border-neutral-subtle);
  border-top: 1px solid var(--colors-border-neutral-subtle);
  border-bottom: 1px solid var(--colors-border-neutral-subtle);
  ;
  border-right: 0;
}

.input-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  padding: 0px 0px 0px var(--space-space-small);
  border-left: 0;
  height: var(--space-size-xlarge);
  border-radius: 0 var(--radius-global-radius-m) var(--radius-global-radius-m) 0;
  background-color: var(--colors-action-formfield-normal);
  border-color: var(--colors-border-neutral-subtle);
  border-right: 1px solid var(--colors-border-neutral-subtle);
  border-top: 1px solid var(--colors-border-neutral-subtle);
  border-bottom: 1px solid var(--colors-border-neutral-subtle);
  ;
  border-left: 0;
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.input-select-normal {
  width: 100%;
  height: var(--space-size-xlarge);
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
  border-radius: var(--radius-global-radius-m);
  background-color: var(--colors-action-formfield-normal);
  border-color: var(--colors-border-neutral-subtle);
  border: 1px solid var(--colors-border-neutral-subtle);
  opacity: 0px;
}

.input-header-normal {
  width: 100%;
  text-align: left;
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.inputs-row {
  align-items: center;
  display: grid;
  /* Utilisation de auto-fit avec minmax pour ajuster dynamiquement le nombre de colonnes */
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: var(--space-space-small);
  position: relative;
  width: 100%;
  padding-top: var(--space-space-2xsmall);
  padding-bottom: var(--space-space-2xsmall);
}

.inputs-row-padfix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.input-container-normal {
  
  flex: 1;
  /* This will make each child div take up equal space */
 display: flex;
 flex-direction: column;
 justify-items: center;
}

.input-container-large {
  flex: 1;
  /* This will make each child div take up equal space */
}

.input-container-right {
  gap: var(--space-space-3xsmall);
}

/*.input-content-normal{

}*/

/*DRAWER - RIGHT PANEL*/

.MuiDrawer-paper {
  border-radius: var(--radius-global-radius-l) 0 0 var(--radius-global-radius-l);
}
.drawer-rfp-form-bloc {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-space-small);
  padding: var(--space-space-large);
  position: relative;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: var(--colors-border-default);
}

.drawer-container {
  background-color: var(--colors-background-default);
  width: 720px;
  
}

.drawer-formfield-inactive {
  
  vertical-align: middle;
  color: var(--colors-content-primary);
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
 
}


/*Tableau*/

/* Global styles applied to all TableCells */
.MuiTableCell-root {
  border-bottom: 1px solid rgba(230, 233, 238, 0.4) !important;
  border-top: none !important;
}

/* Global styles applied to all TableRows */
.MuiTableRow-root {
  height: var(--space-size-2xlarge) !important;
}

.MuiTableRow-root:hover {
  border: none !important;
  background-color: var(--colors-background-brand-subtle) !important;
}

/* Global styles applied to the Paper component that wraps the Table */
.MuiTableContainer-root {
  box-shadow: var(--1) !important;
  border-radius: var(--radius-global-radius-l) !important;
}

.MuiTableCell {

  border-bottom: 1px solid rgba(230, 233, 238, 0.4)!important;
  border-top: none !important;
}

.MuiTableCell-head {
  border-bottom: 1px solid var(--colors-border-default)!important;
}

/*LIST RFP*/


.list-rfp-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px var(--space-space-large) 0px;
  vertical-align: middle;
}

.list-rfp-header-content-l {
  flex: 1;
  color: var(--colors-content-primary);
  font-family: var(--header-large-font-family);
  font-size: var(--header-large-font-size);
  font-style: var(--header-large-font-style);
  font-weight: var(--header-large-font-weight);
  letter-spacing: var(--header-large-letter-spacing);
  line-height: var(--header-large-line-height);
}

.list-rfp-header-content-r {
  width: auto;
  color: var(--colors-action-inverted-normal);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.list-rfp-container {
  height: '100%';

}

.list-table-header {
  color: var(--colors-content-primary);
  font-family: var(--body-strong-medium-font-family);
  font-size: var(--body-strong-medium-font-size);
  font-style: var(--body-strong-medium-font-style);
  font-weight: var(--body-strong-medium-font-weight);
  letter-spacing: var(--body-strong-mediume-letter-spacing);
  line-height: var(--body-strong-medium-line-height);

}

.list-rfp-eq_ref {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: var(--colors-content-primary);
  border: 1px solid var(--colors-border-neutral-subtle);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  -webkit-font-smoothing: antialiased; /* Improve font rendering on Safari */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
}

.list-rfp-title {
  flex: 1;
  color: var(--colors-content-secondary);
  font-family: var(--body-strong-medium-font-family);
  font-size: var(--body-strong-medium-font-size);
  font-style: var(--body-strong-medium-font-style);
  font-weight: var(--body-strong-medium-font-weight);
  letter-spacing: var(--body-strong-medium-letter-spacing);
  line-height: var(--body-strong-medium-line-height);
}

.list-rfp-content {
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  border: 0px;
}
.list-rfp-content-nbr {
  align-self: center;
}

/* react tabs*/
.react-tabs {
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid var(--colors-border-default);
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--colors-border-default);
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.react-tabs__tab {
  width: 100%;
  display: inline-block;
  position: relative;
  list-style: none;
  padding: var(--space-space-small);
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  color: var(--colors-content-primary);
  border-top: 1px solid var(--colors-border-neutral-subtle);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.react-tabs__tab--selected {
  background: transparent;
  color: var(--colors-content-primary);
  border-bottom: 2px solid var(--colors-action-primary-normal) !important;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;

}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;

}

/*Counter*/
.counter {
  display: inline-flex;
  flex-direction: column;
  min-width: var(--space-size-medium);
  align-items: center;
  justify-content: center;
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
  position: relative;
  background-color: var(--colors-background-neutral-on-subtle);
  border-radius: var(--radius-global-radius-m);
}
.counter .number {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  color: var(--colors-content-primary);
  font-size: var(--body-normal-medium-font-size);
  text-align: center;
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  white-space: nowrap;
  font-style: var(--body-normal-medium-font-style);
}
.counter .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  top: -1px;
  left: 26px;
  background-color: var(--colors-background-negative-strong);
  border-radius: 2px;
}
/*TextArea*/
.textareaSK {
  resize: none;
  width: calc( 100% - var(--space-space-xsmall) * 2 );
  background-color: var(--colors-action-formfield-normal);
  border: 1px solid;
  border-color: var(--colors-action-neutral-subtle-normal);
  border-radius: var(--radius-global-radius-m);
  display: flex;
  gap: var(--space-space-2xsmall);
  color: var(--colors-content-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  padding-left: var(--space-space-xsmall);
  padding-top: var(--space-space-2xsmall);
  padding-bottom: var(--space-space-2xsmall);
  padding-right: var(--space-space-xsmall);
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.textareaSK:hover {
  background-color: var(--colors-action-formfield-hover);
  border: 1px solid var(--colors-action-neutral-subtle-hover); 
  color: var(--colors-content-primary);
}

.textareaSK:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3.5px rgba(0, 162, 175, 0.2), 0px 0px 0px 1px rgba(0, 162, 175, 1);
}

/*Applicants list*/
.applicants-list-acronym {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--space-size-large);
  height: var(--space-size-large);
  border-radius: 50%;
  /* makes the shape round */
  background: var(--colors-background-neutral-subtle);
  margin-right: var(--space-space-2xsmall);
}

.item-applicants-lisy-acronym {
  text-align: center;
  vertical-align: middle;
  font-family: var(--body-strong-small-font-family);
  font-size: var(--body-strong-small-font-size);
  font-style: var(--body-strong-small-font-style);
  font-weight: var(--body-strong-small-font-weight);
  letter-spacing: var(--body-strong-small-letter-spacing);
  line-height: var(--body-strong-small-line-height);
  color: var(--colors-content-secondary);
}


/*Table Cells */
.cell-text {
  display: flex;
  flex-direction: column;
  height: var(--space-size-2xlarge);
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
  position: relative;
}
 
.cell-text-label {
  position: relative;
  align-self: stretch;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  color: var(--colors-content-secondary);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-style: var(--body-normal-medium-font-style);
}
 
.cell-text-text-wrapper {
  position: relative;
  align-self: stretch;
  font-family: var(--body-normal-small-font-family);
  font-weight: var(--body-normal-small-font-weight);
  color: var(--colors-content-tertiary);
  font-size: var(--body-normal-small-font-size);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-style: var(--body-normal-small-font-style);
}
/*Modal */
.MuiBox-root { /*Override*/
  margin: 0px !important;
  border-radius: var(--radius-global-radius-l);
  font-family: var(--header-small-font-family);
  font-size: var(--header-small-font-size);
  font-style: var(--header-small-font-style);
  font-weight: var(--header-small-font-weight);
  letter-spacing: var(--header-small-spacing);
  line-height: var(--header-small-line-height);
  color: var(--colors-content-primary);
}
.modal-content {
  
  padding-top: var(--space-space-xsmall);
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  font-style: var(--body-normal-medium-font-style);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.modal-select-align {
  align-content: center;
}

/* Resume  File Dialog*/
.resume-box {
  cursor: pointer;
  align-items: center;
  border: 1px solid var(--colors-border-neutral-subtle); 
  border-radius: var(--radius-global-radius-m);
  background-color: var(--colors-background-neutral-surface);
 
  display: flex;
}
.drop-area{
  border: 1px dotted var(--colors-border-neutral-subtle); 
  border-radius: var(--radius-global-radius-m);
  background-color: var(--colors-background-neutral-surface);
}
.resume-box-padding {
  padding: var(--space-space-xsmall);
}
.resume-icon {
  display: inline-block;
  align-self: center;
  padding-right: var(--space-space-xsmall);
}
.applicant-main {
  width:calc( 100% - 2 * var(--space-space-small));
  padding-left: var(--space-space-small);
  padding-right: var(--space-space-small);
}

/* Wizards */
.wizard-cont {
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}
.flex-cont {
  display: flex;
  padding: auto;
}
.wizard-section-header{
  padding-top: var(--space-space-medium);
  color: var(--colors-content-secondary);
  font-family: var(--header-small-font-family);
  font-size: var(--header-small-font-size);
  font-style: var(--header-small-font-style);
  font-weight: var(--header-small-font-weight);
  letter-spacing: var(--header-small-spacing);
  line-height: var(--header-small-line-height);
  padding-bottom: var(--space-space-medium);
}
.padding-fix {
  padding: var(--space-space-large);
}
.s-padding-fix {
  padding: var(--space-space-small) ;
}
.padding-top-fix {
  padding-top:  var(--space-space-medium) !important;
}
.padding-bottom-small{
  padding-bottom: var(--space-space-2xsmall) !important;
}
.padding-bottom-xsmall{
  padding-bottom: 0px !important;
}
.width-fix {
  width: auto;
}
.h-padding-s {
  padding-left: var(--space-space-small);
  padding-right: var(--space-space-small);
}

.h-padding-l {
  padding-left: var(--space-space-large);
  padding-right: var(--space-space-large);
}

.v-padding-s {
  padding-top: var(--space-space-small);
  padding-bottom: var(--space-space-small);
}
.v-padding-z {
  padding-top: 0;
  padding-bottom: 0;
}
.flex-fix {
  display: flex;
  align-items: center;
}
.standard-line-height{
  height: var(--space-size-medium);
}
.vertical-centering {

  vertical-align: middle;
  align-self: center;
}
.icon-button-fix{
  height: 20px;
  width: 20px;
}
.force-no-border {
  border: 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 120px 1fr 1fr;
  gap: 0;
  border-top: 1px solid var(--colors-border-default);
  border-left: 1px solid var(--colors-border-default);
}
.grid-container-tc {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;
  border-top: 1px solid var(--colors-border-default);
  border-left: 1px solid var(--colors-border-default);
}
.grid-container-right-panel {
  width: 670px;
}
.grid-header, .grid-item {
  padding: var(--space-space-xsmall);
  border-right: 1px solid var(--colors-border-default);
  border-bottom: 1px solid var(--colors-border-default);
}

.grid-header {
  text-align: left;
}
.fixed-width-160 {
  width: 160px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  color: var(--colors-content-secondary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.select-container {
  position: relative;
  /* z-index: 1; Ensure the select dropdown is above other elements */
}

.wizard-skillmatch-borders {
  border: 1px solid var(--colors-border-default);
  border-radius: var(--radius-global-radius-l);
  overflow: visible; /* Allow overflow for dropdowns */
  margin-bottom: var(--space-space-small);
}
.v-padding-fix{
padding-bottom: var(--space-space-small);
}

/*Feedback form*/
.floating-button-div {
  position: fixed;
  bottom: 20px;
  right: 60px;
}
.close-button {
  color: #aaa;
  float: right;
  font-family: var(--header-small-font-family);
  font-size: var(--header-small-font-size);
  font-style: var(--header-small-font-style);
  font-weight: var(--header-small-font-weight);
  letter-spacing: var(--header-small-spacing);
  line-height: var(--header-small-line-height);
  padding-right: var(--space-space-small);
}


/* Dialog */
.dialog {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-space-medium);
  padding: var(--space-space-medium);
  position: relative;
  background-color: var(--colors-background-default);
  border-radius: var(--radius-global-radius-l);
  box-shadow: var(--4);
}

.dialog .primitives {
  display: flex;
  width: 332px;
  align-items: center;
  gap: var(--space-space-2xsmall);
  position: relative;
  flex: 0 0 auto;
}

.dialog .text-wrapper {
  position: relative;
  flex: 1;
  font-family: var(--header-small-font-family);
  font-weight: var(--header-small-font-weight);
  color: var(--colors-content-primary);
  font-size: var(--header-small-font-size);
  letter-spacing: var(--header-small-letter-spacing);
  line-height: var(--header-small-line-height);
  font-style: var(--header-small-font-style);
}

.dialog .icon-button {
  display: flex;
  width: var(--space-size-large);
  height: var(--space-size-large);
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--radius-global-radius-m);
  overflow: hidden;
}

.dialog .before-icon {
  position: relative;
  width: 16px;
  height: 16px;
}

.dialog .primitives-icon {
  justify-content: center;
  display: flex;
  width: 332px;
  align-items: center;
  gap: var(--space-space-2xsmall);
  position: relative;
  flex: 0 0 auto;
}

.dialog .mdi-check {
  position: relative;
  width: 64px;
  height: 64px;
}

.dialog .div {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  color: var(--colors-content-secondary);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  font-style: var(--body-normal-medium-font-style);
}

.dialog .primitives-2 {
  display: flex;
  width: 332px;
  align-items: center;
  justify-content: flex-end;
  gap: var(--space-space-2xsmall);
  position: relative;
  flex: 0 0 auto;
}

.dialog .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  height: var(--space-size-xlarge);
  align-items: center;
  justify-content: center;
  gap: var(--space-space-2xsmall);
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
  position: relative;
  flex: 0 0 auto;
  background-color: var(--colors-action-inverted-normal);
  border-radius: var(--radius-global-radius-m);
  overflow: hidden;
  border: 1px solid;
  border-color: var(--colors-action-outline-normal);
  box-shadow: var(--1);
}

.dialog .button-label {
  position: relative;
  width: fit-content;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  color: var(--colors-action-neutral-normal);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  white-space: nowrap;
  font-style: var(--body-normal-medium-font-style);
}

.dialog .frame {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.dialog .button-label-wrapper {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  height: var(--space-size-xlarge);
  align-items: center;
  justify-content: center;
  gap: var(--space-space-2xsmall);
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
  position: relative;
  flex: 0 0 auto;
  background-color: var(--colors-action-primary-normal);
  border-radius: var(--radius-global-radius-m);
  overflow: hidden;
  box-shadow: var(--1);
}

.dialog .button-label-2 {
  position: relative;
  width: fit-content;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  color: var(--colors-action-inverted-normal);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  white-space: nowrap;
  font-style: var(--body-normal-medium-font-style);
}
/* File dialog */


.search244{
  width: 244px !important
}
.list-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-160{
  width: 100px !important;
  
}

.button-jsti-fix {
  display: grid;
  justify-self: unset;
}

.custom-pagination {
  background-color: transparent !important; /* Use !important if necessary to override default styles */
}

.custom-pagination .MuiTablePagination-toolbar {
  font-family: var(--body-normal-medium-font-family) !important;
  font-weight: var(--body-normal-medium-font-weight) !important;
  color: var(--colors-action-neutral-normal) !important; /* Ensure color applies correctly */
  font-size: var(--body-normal-medium-font-size) !important;
  letter-spacing: var(--body-normal-medium-letter-spacing) !important;
  line-height: var(--body-normal-medium-line-height) !important;
  font-style: var(--body-normal-medium-font-style) !important;
}

.custom-pagination .MuiTablePagination-actions > button {
  color: var(--colors-action-neutral-normal) !important;
}

/* Additional specificity if needed */
.custom-pagination .MuiTablePagination-toolbar,
.custom-pagination .MuiTablePagination-selectRoot,
.custom-pagination .MuiTablePagination-actions {
  font-family: var(--body-normal-medium-font-family) !important;
  font-weight: var(--body-normal-medium-font-weight) !important;
  color: var(--colors-action-neutral-normal) !important;
  font-size: var(--body-normal-medium-font-size) !important;
  letter-spacing: var(--body-normal-medium-letter-spacing) !important;
  line-height: var(--body-normal-medium-line-height) !important;
  font-style: var(--body-normal-medium-font-style) !important;
}

.custom-pagination .MuiTablePagination-selectLabel {
  color: var(--colors-action-neutral-normal) !important;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  font-style: var(--body-normal-medium-font-style);
}

.custom-pagination .MuiTablePagination-select {
  color: var(--colors-action-neutral-normal) !important;
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  font-size: var(--body-normal-medium-font-size);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  font-style: var(--body-normal-medium-font-style);
}

/* Optional: Style the dropdown icon */
.custom-pagination .MuiSelect-icon {
  color: var(--colors-action-neutral-normal) !important;
}
/* Targeting the popper container */
.custom-pagination .MuiPopover-paper {
  background-color: #f5f5f5;
}

/* Targeting the individual dropdown items */
.custom-pagination .MuiMenuItem-root {
  font-family: var(--body-normal-medium-font-family);
  font-weight: var(--body-normal-medium-font-weight);
  font-size: var(--body-normal-medium-font-size);
  color: var(--colors-action-neutral-normal) !important;
}

.custom-pagination .MuiMenuItem-root.Mui-selected {
  background-color: var(--colors-background-default);
}

.custom-pagination .MuiMenuItem-root:hover {
  background-color: var(--colors-background-default);
}
