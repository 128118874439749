.card-client {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var( --colors-background-default);
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0px 1px 2px 0px #2B3C561F;
  width: 100%;
  margin-bottom: 10px;
}
