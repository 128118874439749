:root {
  /* Shadow ------------------------------------------------------------------------ */
  --1: 0px 1px 2px 0px rgba(43, 60, 86, 0.12);
  --2: 0px 2px 8px -1px rgba(27, 36, 44, 0.08), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);
  --3: 0px 8px 16px -2px rgba(27, 36, 44, 0.12), 0px 2px 2px -1px rgba(27, 35, 44, 0.04);
  --4: 0px 16px 24px -6px rgba(27, 36, 44, 0.16), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);

  /* Font ------------------------------------------------------------------------ */
  /* > Body normal */
  --body-normal-large-font-family: "Source Sans 3", Helvetica;
  --body-normal-large-font-size: 14px;
  --body-normal-large-font-style: normal;
  --body-normal-large-font-weight: 400;
  --body-normal-large-letter-spacing: 0.28px;
  --body-normal-large-line-height: 22px;

  --body-normal-medium-font-family: "Source Sans 3", Helvetica;
  --body-normal-medium-font-size: 12px;
  --body-normal-medium-font-style: normal;
  --body-normal-medium-font-weight: 400;
  --body-normal-medium-letter-spacing: 0.24px;
  --body-normal-medium-line-height: 18px;

  --body-normal-small-font-family: "Source Sans 3", Helvetica;
  --body-normal-small-font-size: 10px;
  --body-normal-small-font-style: normal;
  --body-normal-small-font-weight: 400;
  --body-normal-small-letter-spacing: 0.2px;
  --body-normal-small-line-height: 14px;

  --body-normal-xsmall-font-family: "Source Sans 3", Helvetica;
  --body-normal-xsmall-font-size: 8px;
  --body-normal-xsmall-font-style: normal;
  --body-normal-xsmall-font-weight: 400;
  --body-normal-xsmall-letter-spacing: 0.16px;
  --body-normal-xsmall-line-height: 10px;

  /* > Body underline */
  --body-underline-large-font-family: "Source Sans 3", Helvetica;
  --body-underline-large-font-size: 14px;
  --body-underline-large-font-style: normal;
  --body-underline-large-font-weight: 400;
  --body-underline-large-letter-spacing: 0.28px;
  --body-underline-large-line-height: 22px;

  --body-underline-medium-font-family: "Source Sans 3", Helvetica;
  --body-underline-medium-font-size: 12px;
  --body-underline-medium-font-style: normal;
  --body-underline-medium-font-weight: 400;
  --body-underline-medium-letter-spacing: 0.24px;
  --body-underline-medium-line-height: 18px;

  --body-underline-small-font-family: "Source Sans 3", Helvetica;
  --body-underline-small-font-size: 10px;
  --body-underline-small-font-style: normal;
  --body-underline-small-font-weight: 400;
  --body-underline-small-letter-spacing: 0.2px;
  --body-underline-small-line-height: 14px;

  --body-underline-xsmall-font-family: "Source Sans 3", Helvetica;
  --body-underline-xsmall-font-size: 8px;
  --body-underline-xsmall-font-style: normal;
  --body-underline-xsmall-font-weight: 400;
  --body-underline-xsmall-letter-spacing: 0.16px;
  --body-underline-xsmall-line-height: 10px;

  /* > Body Strong */
  --body-strong-large-font-family: "Source Sans 3", Helvetica;
  --body-strong-large-font-size: 14px;
  --body-strong-large-font-style: normal;
  --body-strong-large-font-weight: 600;
  --body-strong-large-letter-spacing: 0.28px;
  --body-strong-large-line-height: 22px;

  --body-strong-medium-font-family: "Source Sans 3", Helvetica;
  --body-strong-medium-font-size: 12px;
  --body-strong-medium-font-style: normal;
  --body-strong-medium-font-weight: 600;
  --body-strong-medium-letter-spacing: 0.24px;
  --body-strong-medium-line-height: 18px;

  --body-strong-small-font-family: "Source Sans 3", Helvetica;
  --body-strong-small-font-size: 10px;
  --body-strong-small-font-style: normal;
  --body-strong-small-font-weight: 600;
  --body-strong-small-letter-spacing: 0.2px;
  --body-strong-small-line-height: 14px;

  --body-strong-xsmall-font-family: "Source Sans 3", Helvetica;
  --body-strong-xsmall-font-size: 8px;
  --body-strong-xsmall-font-style: normal;
  --body-strong-xsmall-font-weight: 600;
  --body-strong-xsmall-letter-spacing: 0.16px;
  --body-strong-xsmall-line-height: 10px;

  /* > Body Strong underline */
  --body-strong-underline-large-font-family: "Source Sans 3", Helvetica;
  --body-strong-underline-large-font-size: 16px;
  --body-strong-underline-large-font-style: normal;
  --body-strong-underline-large-font-weight: 600;
  --body-strong-underline-large-letter-spacing: 0.32px;
  --body-strong-underline-large-line-height: 24px;

  --body-strong-underline-medium-font-family: "Source Sans 3", Helvetica;
  --body-strong-underline-medium-font-size: 14px;
  --body-strong-underline-medium-font-style: normal;
  --body-strong-underline-medium-font-weight: 600;
  --body-strong-underline-medium-letter-spacing: 0.28px;
  --body-strong-underline-medium-line-height: 22px;

  --body-strong-underline-small-font-family: "Source Sans 3", Helvetica;
  --body-strong-underline-small-font-size: 12px;
  --body-strong-underline-small-font-style: normal;
  --body-strong-underline-small-font-weight: 600;
  --body-strong-underline-small-letter-spacing: 0.24px;
  --body-strong-underline-small-line-height: 18px;

  --body-strong-underline-xsmall-font-family: "Source Sans 3", Helvetica;
  --body-strong-underline-xsmall-font-size: 10px;
  --body-strong-underline-xsmall-font-style: normal;
  --body-strong-underline-xsmall-font-weight: 600;
  --body-strong-underline-xsmall-letter-spacing: 0.2px;
  --body-strong-underline-xsmall-line-height: 14px;

  /* > Header */
  --header-large-font-family: "Source Sans 3", Helvetica;
  --header-large-font-size: 24px;
  --header-large-font-style: normal;
  --header-large-font-weight: 600;
  --header-large-letter-spacing: 0.48px;
  --header-large-line-height: 34px;

  --header-medium-font-family: "Source Sans 3", Helvetica;
  --header-medium-font-size: 18px;
  --header-medium-font-style: normal;
  --header-medium-font-weight: 600;
  --header-medium-letter-spacing: 0.36px;
  --header-medium-line-height: 28px;

  --header-small-font-family: "Source Sans 3", Helvetica;
  --header-small-font-size: 16px;
  --header-small-font-style: normal;
  --header-small-font-weight: 600;
  --header-small-letter-spacing: 0.32px;
  --header-small-line-height: 24px;

  --header-xsmall-font-family: "Source Sans 3", Helvetica;
  --header-xsmall-font-size: 12px;
  --header-xsmall-font-style: normal;
  --header-xsmall-font-weight: 600;
  --header-xsmall-letter-spacing: 0.24px;
  --header-xsmall-line-height: 18px;
  
  /* Colors ------------------------------------------------------------------------ */
  /* > Global */
  /* > > Global/Global Blue */
  --colors-global-blue-blue-100: rgba(247, 252, 254, 1);
  --colors-global-blue-blue-1000: rgba(41, 168, 209, 1);
  --colors-global-blue-blue-1100: rgba(38, 154, 191, 1);
  --colors-global-blue-blue-1200: rgba(31, 124, 154, 1);
  --colors-global-blue-blue-1300: rgba(21, 84, 104, 1);
  --colors-global-blue-blue-200: rgba(242, 251, 253, 1);
  --colors-global-blue-blue-300: rgba(234, 248, 252, 1);
  --colors-global-blue-blue-400: rgba(228, 246, 251, 1);
  --colors-global-blue-blue-500: rgba(213, 241, 249, 1);
  --colors-global-blue-blue-600: rgba(200, 236, 248, 1);
  --colors-global-blue-blue-700: rgba(142, 216, 240, 1);
  --colors-global-blue-blue-800: rgba(108, 205, 235, 1);
  --colors-global-blue-blue-900: rgba(45, 183, 227, 1);

  /* > > Global/Global Deep Azure */
  --colors-global-deep-azure-deepazure-100: rgba(247, 248, 250, 1);
  --colors-global-deep-azure-deepazure-1000: rgba(58, 81, 116, 1);
  --colors-global-deep-azure-deepazure-1100: rgba(53, 74, 106, 1);
  --colors-global-deep-azure-deepazure-1200: rgba(43, 60, 86, 1);
  --colors-global-deep-azure-deepazure-1300: rgba(29, 40, 58, 1);
  --colors-global-deep-azure-deepazure-200: rgba(244, 245, 247, 1);
  --colors-global-deep-azure-deepazure-300: rgba(236, 238, 242, 1);
  --colors-global-deep-azure-deepazure-400: rgba(230, 233, 238, 1);
  --colors-global-deep-azure-deepazure-500: rgba(217, 222, 229, 1);
  --colors-global-deep-azure-deepazure-600: rgba(206, 212, 221, 1);
  --colors-global-deep-azure-deepazure-700: rgba(153, 165, 184, 1);
  --colors-global-deep-azure-deepazure-800: rgba(123, 139, 163, 1);
  --colors-global-deep-azure-deepazure-900: rgba(63, 88, 126, 1);

  /* > > Global/Global Green */
  --colors-global-green-green-100: rgba(245, 253, 251, 1);
  --colors-global-green-green-1000: rgba(0, 188, 154, 1);
  --colors-global-green-green-1100: rgba(0, 171, 140, 1);
  --colors-global-green-green-1200: rgba(0, 139, 114, 1);
  --colors-global-green-green-1300: rgba(0, 94, 77, 1);
  --colors-global-green-green-200: rgba(240, 252, 250, 1);
  --colors-global-green-green-300: rgba(230, 250, 246, 1);
  --colors-global-green-green-400: rgba(222, 248, 244, 1);
  --colors-global-green-green-500: rgba(204, 245, 237, 1);
  --colors-global-green-green-600: rgba(189, 242, 232, 1);
  --colors-global-green-green-700: rgba(117, 227, 207, 1);
  --colors-global-green-green-800: rgba(77, 219, 193, 1);
  --colors-global-green-green-900: rgba(0, 204, 167, 1);

  /* > > Global/Global orange */
  --colors-global-orange-orange-100: rgba(255, 252, 250, 1);
  --colors-global-orange-orange-1000: rgba(235, 155, 115, 1);
  --colors-global-orange-orange-1100: rgba(214, 141, 105, 1);
  --colors-global-orange-orange-1200: rgba(173, 114, 85, 1);
  --colors-global-orange-orange-1300: rgba(117, 77, 58, 1);
  --colors-global-orange-orange-200: rgba(255, 250, 247, 1);
  --colors-global-orange-orange-300: rgba(255, 246, 242, 1);
  --colors-global-orange-orange-400: rgba(255, 244, 238, 1);
  --colors-global-orange-orange-500: rgba(255, 238, 229, 1);
  --colors-global-orange-orange-600: rgba(255, 232, 221, 1);
  --colors-global-orange-orange-700: rgba(255, 208, 185, 1);
  --colors-global-orange-orange-800: rgba(255, 194, 164, 1);
  --colors-global-orange-orange-900: rgba(255, 168, 125, 1);

  /* > > Global/Global Red */
  --colors-global-red-red-100: rgba(255, 248, 249, 1);
  --colors-global-red-red-1000: rgba(235, 70, 93, 1);
  --colors-global-red-red-1100: rgba(214, 64, 85, 1);
  --colors-global-red-red-1200: rgba(173, 52, 69, 1);
  --colors-global-red-red-1300: rgba(117, 35, 46, 1);
  --colors-global-red-red-200: rgba(255, 244, 246, 1);
  --colors-global-red-red-300: rgba(255, 237, 240, 1);
  --colors-global-red-red-400: rgba(255, 232, 235, 1);
  --colors-global-red-red-500: rgba(255, 219, 224, 1);
  --colors-global-red-red-600: rgba(255, 208, 215, 1);
  --colors-global-red-red-700: rgba(255, 158, 172, 1);
  --colors-global-red-red-800: rgba(255, 130, 147, 1);
  --colors-global-red-red-900: rgba(255, 76, 101, 1);

  /* > > Global/Global Wave */
  --colors-global-wave-wave-100: rgba(249, 253, 253, 1);
  --colors-global-wave-wave-1000: rgba(0, 141, 154, 1);
  --colors-global-wave-wave-1100: rgba(0, 112, 123, 1);
  --colors-global-wave-wave-1200: rgba(0, 80, 88, 1);
  --colors-global-wave-wave-1300: rgba(0, 48, 53, 1);
  --colors-global-wave-wave-200: rgba(245, 251, 252, 1);
  --colors-global-wave-wave-300: rgba(235, 247, 249, 1);
  --colors-global-wave-wave-400: rgba(214, 240, 242, 1);
  --colors-global-wave-wave-500: rgba(194, 232, 236, 1);
  --colors-global-wave-wave-600: rgba(163, 221, 226, 1);
  --colors-global-wave-wave-700: rgba(128, 208, 215, 1);
  --colors-global-wave-wave-800: rgba(87, 192, 202, 1);
  --colors-global-wave-wave-900: rgba(0, 160, 175, 1);

  /* > > Global/Global White */
  --colors-global-white-transparent: rgba(255, 255, 255, 0);
  --colors-global-white-white-100: rgba(255, 255, 255, 0.02);
  --colors-global-white-white-1000: rgba(255, 255, 255, 0.65);
  --colors-global-white-white-1100: rgba(255, 255, 255, 0.7);
  --colors-global-white-white-1200: rgba(255, 255, 255, 0.8);
  --colors-global-white-white-1300: rgba(255, 255, 255, 1);
  --colors-global-white-white-200: rgba(255, 255, 255, 0.03);
  --colors-global-white-white-300: rgba(255, 255, 255, 0.05);
  --colors-global-white-white-400: rgba(255, 255, 255, 0.08);
  --colors-global-white-white-500: rgba(255, 255, 255, 0.1);
  --colors-global-white-white-600: rgba(255, 255, 255, 0.12);
  --colors-global-white-white-700: rgba(255, 255, 255, 0.25);
  --colors-global-white-white-800: rgba(255, 255, 255, 0.4);
  --colors-global-white-white-900: rgba(255, 255, 255, 0.6);

  /* > Semantic */
  /* > > Semantic/Brand */
  --colors-semantic-brand-brand-100: var(--colors-global-wave-wave-100);
  --colors-semantic-brand-brand-1000: var(--colors-global-wave-wave-1000);
  --colors-semantic-brand-brand-1100: var(--colors-global-wave-wave-1100);
  --colors-semantic-brand-brand-1200: var(--colors-global-wave-wave-1200);
  --colors-semantic-brand-brand-1300: var(--colors-global-wave-wave-1300);
  --colors-semantic-brand-brand-200: var(--colors-global-wave-wave-200);
  --colors-semantic-brand-brand-300: var(--colors-global-wave-wave-300);
  --colors-semantic-brand-brand-400: var(--colors-global-wave-wave-400);
  --colors-semantic-brand-brand-500: var(--colors-global-wave-wave-500);
  --colors-semantic-brand-brand-600: var(--colors-global-wave-wave-600);
  --colors-semantic-brand-brand-700: var(--colors-global-wave-wave-700);
  --colors-semantic-brand-brand-800: var(--colors-global-wave-wave-800);
  --colors-semantic-brand-brand-900: var(--colors-global-wave-wave-900);

  /* > > Semantic/Informative */
  --colors-semantic-informative-informative-100: var(--colors-global-blue-blue-100);
  --colors-semantic-informative-informative-1000: var(--colors-global-blue-blue-1000);
  --colors-semantic-informative-informative-1100: var(--colors-global-blue-blue-1100);
  --colors-semantic-informative-informative-1200: var(--colors-global-blue-blue-1200);
  --colors-semantic-informative-informative-1300: var(--colors-global-blue-blue-1300);
  --colors-semantic-informative-informative-200: var(--colors-global-blue-blue-200);
  --colors-semantic-informative-informative-300: var(--colors-global-blue-blue-300);
  --colors-semantic-informative-informative-400: var(--colors-global-blue-blue-400);
  --colors-semantic-informative-informative-500: var(--colors-global-blue-blue-500);
  --colors-semantic-informative-informative-600: var(--colors-global-blue-blue-600);
  --colors-semantic-informative-informative-700: var(--colors-global-blue-blue-700);
  --colors-semantic-informative-informative-800: var(--colors-global-blue-blue-800);
  --colors-semantic-informative-informative-900: var(--colors-global-blue-blue-900);

  /* > > Semantic/Negative */
  --colors-semantic-negative-negative-100: var(--colors-global-red-red-100);
  --colors-semantic-negative-negative-1000: var(--colors-global-red-red-1000);
  --colors-semantic-negative-negative-1100: var(--colors-global-red-red-1100);
  --colors-semantic-negative-negative-1200: var(--colors-global-red-red-1200);
  --colors-semantic-negative-negative-1300: var(--colors-global-red-red-1300);
  --colors-semantic-negative-negative-200: var(--colors-global-red-red-200);
  --colors-semantic-negative-negative-300: var(--colors-global-red-red-300);
  --colors-semantic-negative-negative-400: var(--colors-global-red-red-400);
  --colors-semantic-negative-negative-500: var(--colors-global-red-red-500);
  --colors-semantic-negative-negative-600: var(--colors-global-red-red-600);
  --colors-semantic-negative-negative-700: var(--colors-global-red-red-700);
  --colors-semantic-negative-negative-800: var(--colors-global-red-red-800);
  --colors-semantic-negative-negative-900: var(--colors-global-red-red-900);

  /* > > Semantic/Neutral */
  --colors-semantic-neutral-neutral-100: var(--colors-global-deep-azure-deepazure-100);
  --colors-semantic-neutral-neutral-1000: var(--colors-global-deep-azure-deepazure-1000);
  --colors-semantic-neutral-neutral-1100: var(--colors-global-deep-azure-deepazure-1100);
  --colors-semantic-neutral-neutral-1200: var(--colors-global-deep-azure-deepazure-1200);
  --colors-semantic-neutral-neutral-1300: var(--colors-global-deep-azure-deepazure-1300);
  --colors-semantic-neutral-neutral-200: var(--colors-global-deep-azure-deepazure-200);
  --colors-semantic-neutral-neutral-300: var(--colors-global-deep-azure-deepazure-300);
  --colors-semantic-neutral-neutral-400: var(--colors-global-deep-azure-deepazure-400);
  --colors-semantic-neutral-neutral-500: var(--colors-global-deep-azure-deepazure-500);
  --colors-semantic-neutral-neutral-600: var(--colors-global-deep-azure-deepazure-600);
  --colors-semantic-neutral-neutral-700: var(--colors-global-deep-azure-deepazure-700);
  --colors-semantic-neutral-neutral-800: var(--colors-global-deep-azure-deepazure-800);
  --colors-semantic-neutral-neutral-900: var(--colors-global-deep-azure-deepazure-900);

  /* > > Semantic/Positive */
  --colors-semantic-positive-positive-100: var(--colors-global-green-green-100);
  --colors-semantic-positive-positive-1000: var(--colors-global-green-green-1000);
  --colors-semantic-positive-positive-1100: var(--colors-global-green-green-1100);
  --colors-semantic-positive-positive-1200: var(--colors-global-green-green-1200);
  --colors-semantic-positive-positive-1300: var(--colors-global-green-green-1300);
  --colors-semantic-positive-positive-200: var(--colors-global-green-green-200);
  --colors-semantic-positive-positive-300: var(--colors-global-green-green-300);
  --colors-semantic-positive-positive-400: var(--colors-global-green-green-400);
  --colors-semantic-positive-positive-500: var(--colors-global-green-green-500);
  --colors-semantic-positive-positive-600: var(--colors-global-green-green-600);
  --colors-semantic-positive-positive-700: var(--colors-global-green-green-700);
  --colors-semantic-positive-positive-800: var(--colors-global-green-green-800);
  --colors-semantic-positive-positive-900: var(--colors-global-green-green-900);

  /* > > Semantic/Warning */
  --colors-semantic-warning-warning-100: var(--colors-global-orange-orange-100);
  --colors-semantic-warning-warning-1000: var(--colors-global-orange-orange-1000);
  --colors-semantic-warning-warning-1100: var(--colors-global-orange-orange-1100);
  --colors-semantic-warning-warning-1200: var(--colors-global-orange-orange-1200);
  --colors-semantic-warning-warning-1300: var(--colors-global-orange-orange-1300);
  --colors-semantic-warning-warning-200: var(--colors-global-orange-orange-200);
  --colors-semantic-warning-warning-300: var(--colors-global-orange-orange-300);
  --colors-semantic-warning-warning-400: var(--colors-global-orange-orange-400);
  --colors-semantic-warning-warning-500: var(--colors-global-orange-orange-500);
  --colors-semantic-warning-warning-600: var(--colors-global-orange-orange-600);
  --colors-semantic-warning-warning-700: var(--colors-global-orange-orange-700);
  --colors-semantic-warning-warning-800: var(--colors-global-orange-orange-800);
  --colors-semantic-warning-warning-900: var(--colors-global-orange-orange-900);

  /* > Action */
  /* > > Action/FormField */
  --colors-action-formfield-hover: var(--colors-semantic-neutral-neutral-300);
  --colors-action-formfield-normal: var(--colors-semantic-neutral-neutral-100);

  /* > > Action/Ghost */
  --colors-action-ghost-brand-active: var(--colors-semantic-brand-brand-900);
  --colors-action-ghost-brand-hover: var(--colors-semantic-neutral-neutral-300);
  --colors-action-ghost-brand-normal: var(--colors-global-white-transparent);
  --colors-action-ghost-brand-selected: var(--colors-semantic-brand-brand-900);

  /* > > Action/Informative */
  --colors-action-informative-active: var(--colors-semantic-informative-informative-1100);
  --colors-action-informative-hover: var(--colors-semantic-informative-informative-1100);
  --colors-action-informative-normal: var(--colors-semantic-informative-informative-900);
  --colors-action-informative-selected: var(--colors-semantic-informative-informative-1000);
  --colors-action-informative-subtle-active: var(--colors-semantic-informative-informative-600);
  --colors-action-informative-subtle-hover: var(--colors-semantic-informative-informative-400);
  --colors-action-informative-subtle-normal: var(--colors-semantic-informative-informative-300);
  --colors-action-informative-subtle-selected: var(--colors-semantic-negative-negative-400);

  /* > > Action/Inverted */
  --colors-action-inverted-active: var(--colors-global-white-white-1100);
  --colors-action-inverted-hover: var(--colors-global-white-white-1200);
  --colors-action-inverted-normal: var(--colors-global-white-white-1300);
  --colors-action-inverted-selected: var(--colors-global-white-white-1200);

  /* > > Action/Negative */
  --colors-action-negative-active: var(--colors-semantic-negative-negative-1100);
  --colors-action-negative-hover: var(--colors-semantic-negative-negative-1100);
  --colors-action-negative-normal: var(--colors-semantic-negative-negative-900);
  --colors-action-negative-selected: var(--colors-semantic-negative-negative-1000);
  --colors-action-negative-subtle-active: var(--colors-semantic-negative-negative-600);
  --colors-action-negative-subtle-hover: var(--colors-semantic-negative-negative-400);
  --colors-action-negative-subtle-normal: var(--colors-semantic-negative-negative-300);
  --colors-action-negative-subtle-selected: var(--colors-semantic-negative-negative-400);

  /* > > Action/Neutral */
  --colors-action-neutral-active: var(--colors-semantic-neutral-neutral-1200);
  --colors-action-neutral-hover: var(--colors-semantic-neutral-neutral-1300);
  --colors-action-neutral-normal: var(--colors-semantic-neutral-neutral-900);
  --colors-action-neutral-selected: var(--colors-semantic-neutral-neutral-1100);
  --colors-action-neutral-subtle-active: var(--colors-semantic-neutral-neutral-600);
  --colors-action-neutral-subtle-hover: var(--colors-semantic-neutral-neutral-500);
  --colors-action-neutral-subtle-normal: var(--colors-semantic-neutral-neutral-300);
  --colors-action-neutral-subtle-selected: var(--colors-semantic-neutral-neutral-400);

  /* > > Action/Outline */
  --colors-action-outline-active: var(--colors-semantic-brand-brand-900);
  --colors-action-outline-hover: var(--colors-semantic-neutral-neutral-900);
  --colors-action-outline-normal: var(--colors-semantic-neutral-neutral-600);
  --colors-action-outline-selected: var(--colors-semantic-brand-brand-900);

  /* > > Action/Positive */
  --colors-action-positive-active: var(--colors-semantic-positive-positive-1100);
  --colors-action-positive-hover: var(--colors-semantic-positive-positive-1100);
  --colors-action-positive-normal: var(--colors-semantic-positive-positive-900);
  --colors-action-positive-selected: var(--colors-semantic-positive-positive-1000);
  --colors-action-positive-subtle-active: var(--colors-semantic-positive-positive-600);
  --colors-action-positive-subtle-hover: var(--colors-semantic-positive-positive-400);
  --colors-action-positive-subtle-normal: var(--colors-semantic-positive-positive-300);
  --colors-action-positive-subtle-selected: var(--colors-semantic-positive-positive-400);

  /* > > Action/Primary */
  --colors-action-primary-active: var(--colors-semantic-brand-brand-1100);
  --colors-action-primary-hover: var(--colors-semantic-brand-brand-1100);
  --colors-action-primary-normal: var(--colors-semantic-brand-brand-900);
  --colors-action-primary-selected: var(--colors-semantic-brand-brand-1000);
  --colors-action-primary-subtle-active: var(--colors-semantic-brand-brand-600);
  --colors-action-primary-subtle-hover: var(--colors-semantic-brand-brand-400);
  --colors-action-primary-subtle-normal: var(--colors-semantic-brand-brand-300);
  --colors-action-primary-subtle-selected: var(--colors-semantic-brand-brand-400);

  /* > Background */
  /* > > Background/Brand */
  --colors-background-brand-muted: var(--colors-semantic-brand-brand-600);
  --colors-background-brand-on-subtle: var(--colors-semantic-brand-brand-400);
  --colors-background-brand-strong: var(--colors-semantic-brand-brand-900);
  --colors-background-brand-subtle: var(--colors-semantic-brand-brand-200);
  --colors-background-brand-surface: var(--colors-semantic-brand-brand-100);

  /* > > Background/Default */
  --colors-background-default: var(--colors-global-white-white-1300);

  /* > > Background/Informative */
  --colors-background-informative-muted: var(--colors-semantic-informative-informative-600);
  --colors-background-informative-on-subtle: var(--colors-semantic-informative-informative-400);
  --colors-background-informative-strong: var(--colors-semantic-informative-informative-900);
  --colors-background-informative-subtle: var(--colors-semantic-informative-informative-200);
  --colors-background-informative-surface: var(--colors-semantic-informative-informative-100);

  /* > > Background/Inverted */
  --colors-background-inverted: var(--colors-semantic-neutral-neutral-1200);

  /* > > Background/Negative */
  --colors-background-negative-muted: var(--colors-semantic-negative-negative-600);
  --colors-background-negative-on-subtle: var(--colors-semantic-negative-negative-400);
  --colors-background-negative-strong: var(--colors-semantic-negative-negative-900);
  --colors-background-negative-subtle: var(--colors-semantic-negative-negative-200);
  --colors-background-negative-surface: var(--colors-semantic-negative-negative-100);

  /* > > Background/Neutral */
  --colors-background-neutral-muted: var(--colors-semantic-neutral-neutral-600);
  --colors-background-neutral-on-subtle: var(--colors-semantic-neutral-neutral-400);
  --colors-background-neutral-strong: var(--colors-semantic-neutral-neutral-900);
  --colors-background-neutral-subtle: var(--colors-semantic-neutral-neutral-200);
  --colors-background-neutral-surface: var(--colors-semantic-neutral-neutral-100);

  /* > > Background/Positive */
  --colors-background-positive-muted: var(--colors-semantic-positive-positive-600);
  --colors-background-positive-on-subtle: var(--colors-semantic-positive-positive-400);
  --colors-background-positive-strong: var(--colors-semantic-positive-positive-900);
  --colors-background-positive-subtle: var(--colors-semantic-positive-positive-200);
  --colors-background-positive-surface: var(--colors-semantic-positive-positive-100);

  /* > > Background/Warning */
  --colors-background-warning-muted: var(--colors-semantic-warning-warning-600);
  --colors-background-warning-on-subtle: var(--colors-semantic-warning-warning-400);
  --colors-background-warning-strong: var(--colors-semantic-warning-warning-900);
  --colors-background-warning-subtle: var(--colors-semantic-warning-warning-200);
  --colors-background-warning-surface: var(--colors-semantic-warning-warning-100);

  /* Border */
  /* > > Border/Brand */
  --colors-border-brand-strong: var(--colors-semantic-brand-brand-900);
  --colors-border-brand-subtle: var(--colors-semantic-brand-brand-600);

  /* > > Border/default */
  --colors-border-default: var(--colors-semantic-neutral-neutral-400);

  /* > > Border/Informative */
  --colors-border-informative-strong: var(--colors-semantic-informative-informative-900);
  --colors-border-informative-subtle: var(--colors-semantic-informative-informative-600);

  /* > > Border/Inverted */
  --colors-border-inverted: var(--colors-global-white-white-1300);

  /* > > Border/Negative */
  --colors-border-negative-strong: var(--colors-semantic-negative-negative-900);
  --colors-border-negative-subtle: var(--colors-semantic-negative-negative-600);

  /* > > Border/Neutral */
  --colors-border-neutral-strong: var(--colors-semantic-neutral-neutral-900);
  --colors-border-neutral-subtle: var(--colors-semantic-neutral-neutral-600);

  /* > > Border/Positive */
  --colors-border-positive-strong: var(--colors-semantic-positive-positive-900);
  --colors-border-positive-subtle: var(--colors-semantic-positive-positive-600);

  /* > > Border/Warning */
  --colors-border-warning-strong: var(--colors-semantic-warning-warning-900);
  --colors-border-warning-subtle: var(--colors-semantic-warning-warning-600);

  /* > > Border/Brand */
  --colors-content-brand-primary: var(--colors-semantic-brand-brand-1100);
  --colors-content-brand-secondary: var(--colors-semantic-brand-brand-900);

  /* Content */
  /* > > Content/Disabled */
  --colors-content-disabled: var(--colors-semantic-neutral-neutral-700);

  /* > > Content/Informative */
  --colors-content-informative-primary: var(--colors-semantic-informative-informative-1100);
  --colors-content-informative-secondary: var(--colors-semantic-informative-informative-900);

  /* > > Content/Inverted */
  --colors-content-inverted-disabled: var(--colors-global-white-white-700);
  --colors-content-inverted-primary: var(--colors-global-white-white-1300);
  --colors-content-inverted-secondary: var(--colors-global-white-white-900);
  --colors-content-inverted-tertiary: var(--colors-global-white-white-800);

  /* > > Content/Negative */
  --colors-content-negative-primary: var(--colors-semantic-negative-negative-1100);
  --colors-content-negative-secondary: var(--colors-semantic-negative-negative-900);

  /* > > Content/Positive */
  --colors-content-positive-primary: var(--colors-semantic-positive-positive-1100);
  --colors-content-positive-secondary: var(--colors-semantic-positive-positive-900);

  /* > > Content/Default */
  --colors-content-primary: var(--colors-semantic-neutral-neutral-1200);
  --colors-content-secondary: var(--colors-semantic-neutral-neutral-900);
  --colors-content-tertiary: var(--colors-semantic-neutral-neutral-800);

  /* > > Content/Warning */
  --colors-content-warning-primary: var(--colors-semantic-warning-warning-1100);
  --colors-content-warning-secondary: var(--colors-semantic-warning-warning-900);
  
  /* Frame ------------------------------------------------------------------------ */
  
  --frame-resize-3-collumns-max-width: 354px;
  --frame-resize-3-collumns-min-width: 354px;
  --frame-resize-collumn-max-width: 1094px;
  --frame-resize-collumn-min-width: 354px;
  --frame-resize-frame-frame-size: 1158px;
  
  /* Radius ------------------------------------------------------------------------ */
  
  --radius-global-radius-l: 8px;
  --radius-global-radius-m: 4px;
  --radius-global-radius-null: 0px;
  --radius-global-radius-round: 9999px;
  --radius-global-radius-s: 2px;
  --radius-global-radius-xl: 16px;

  /* Space ------------------------------------------------------------------------ */
  /* > Dimensions */
  --space-dimensions-dimension-0: 4px;
  --space-dimensions-dimension-1: 8px;
  --space-dimensions-dimension-10: 72px;
  --space-dimensions-dimension-2: 12px;
  --space-dimensions-dimension-3: 16px;
  --space-dimensions-dimension-4: 24px;
  --space-dimensions-dimension-5: 32px;
  --space-dimensions-dimension-6: 40px;
  --space-dimensions-dimension-7: 48px;
  --space-dimensions-dimension-8: 56px;
  --space-dimensions-dimension-9: 64px;

  /* > Size */
  --space-size-2xlarge: var(--space-dimensions-dimension-7);
  --space-size-3xlarge: var(--space-dimensions-dimension-8);
  --space-size-4xlarge: var(--space-dimensions-dimension-9);
  --space-size-5xlarge: var(--space-dimensions-dimension-10);
  --space-size-large: var(--space-dimensions-dimension-5);
  --space-size-medium: var(--space-dimensions-dimension-4);
  --space-size-small: var(--space-dimensions-dimension-3);
  --space-size-xlarge: var(--space-dimensions-dimension-6);
  --space-size-xsmall: var(--space-dimensions-dimension-1);

  /* > Space */
  --space-space-2xlarge: var(--space-dimensions-dimension-7);
  --space-space-2xsmall: var(--space-dimensions-dimension-1);
  --space-space-3xlarge: var(--space-dimensions-dimension-8);
  --space-space-3xsmall: var(--space-dimensions-dimension-0);
  --space-space-4xlarge: var(--space-dimensions-dimension-9);
  --space-space-5xlarge: var(--space-dimensions-dimension-10);
  --space-space-large: var(--space-dimensions-dimension-5);
  --space-space-medium: var(--space-dimensions-dimension-4);
  --space-space-small: var(--space-dimensions-dimension-3);
  --space-space-xlarge: var(--space-dimensions-dimension-6);
  --space-space-xsmall: var(--space-dimensions-dimension-2);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-frame-resize-mode="x-large">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-frame-resize-mode="x-large"] {
  --frame-resize-3-collumns-max-width: 354px;
  --frame-resize-3-collumns-min-width: 354px;
  --frame-resize-collumn-max-width: 1094px;
  --frame-resize-collumn-min-width: 354px;
  --frame-resize-frame-frame-size: 1158px;
}

[data-frame-resize-mode="large"] {
  --frame-resize-3-collumns-max-width: 208px;
  --frame-resize-3-collumns-min-width: 208px;
  --frame-resize-collumn-max-width: 656px;
  --frame-resize-collumn-min-width: 208px;
  --frame-resize-frame-frame-size: 720px;
}

[data-frame-resize-mode="small"] {
  --frame-resize-3-collumns-max-width: 354px;
  --frame-resize-3-collumns-min-width: 354px;
  --frame-resize-collumn-max-width: 354px;
  --frame-resize-collumn-min-width: 354px;
  --frame-resize-frame-frame-size: 418px;
}
