.wizard-items {
  flex: 1;
  margin: 0 0px; /* Optional: adjust spacing between items */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wizard-items .overlap-group {
  height: 120px; /* Fixed height */
  width: 100%; /* Allow full width */
  position: relative;
  top: -1px;
}

.wizard-items .bg {
  width: 100%; /* Stretch horizontally */
  height: 100%; /* Maintain fixed height */
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover; /* Ensure the SVG scales correctly */
}

.wizard-items .text-icon {
  align-items: center;
  display: inline-flex;
  gap: var(--space-space-3xsmall);
}

/* Ensure the SVG icons have fixed height and width */
.wizard-items .mdi-radiobox-marked {
  height: 20px !important;
  width: 20px !important;
  position: relative !important;
}

/* Additional styling as needed */


.wizard-items .step-name {
  color: var(--colors-content-inverted-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  width: 100%;
}

.wizard-items .text-wrapper {
  color: var(--colors-content-tertiary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  margin-top: -1px;
  position: relative;
  width: 100%;
}

.wizard-items .div {
  color: var(--colors-content-inverted-primary);
  position: relative;
  width: 100%;
}

.wizard-items.middle {
  width: 100%;

}

.wizard-items.end {
  width: 100%;

}

.wizard-items.inactive.initial {
  width: 100%;
}

.wizard-items.initial.active {
  width: 100%;
}

.wizard-items.initial.completed {
  width: 100%;
}

.wizard-items.middle .overlap-group {
  background-size: 100% 100%;
  width: 100%;
}

.wizard-items.inactive .overlap-group {
  background-size: 100% 100%;
}

.wizard-items.initial .overlap-group {
  background-size: 100% 100%;
}

.wizard-items.completed.middle .overlap-group {
  background-image: url(/img/bg-14.svg);
}

.wizard-items.end.inactive .overlap-group {
  background-image: url(/img/bg-17.svg);
  width: 100%;
  ;
}

.wizard-items.inactive.initial .overlap-group {
  background-image: url(/img/bg-12.svg);
  width: 100%;
}

.wizard-items.inactive.middle .overlap-group {
  background-image: url(/img/bg.svg);
}

.wizard-items.initial.active .overlap-group {
  background-image: url(/img/bg-11.svg);
  width: 100%;
}

.wizard-items.end.completed .overlap-group {
  width: 100%;
}

.wizard-items.initial.completed .overlap-group {
  background-image: url(/img/bg-10.svg);
  width: 100%;
}

.wizard-items.end.active .overlap-group {
  width: 100%;
}

.wizard-items.active.middle .overlap-group {
  background-image: url(/img/bg-13.svg);
}

.wizard-items.active .text-icon {
  top: 40%;
}

.wizard-items.middle .text-icon {
  left: 31px;
  position: relative;
}

.wizard-items.inactive .text-icon {
  position: relative;
  top: 40%;
}

.wizard-items.end .text-icon {
  left: 31px;
}

.wizard-items.completed .text-icon {
  top: 40%;
}

.wizard-items.initial .text-icon {
  left: 17px;
  position: relative;
}

.wizard-items.end.completed .text-icon {
  position: absolute;
}

.wizard-items.end.active .text-icon {
  position: absolute;
}

.wizard-items.end .div {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.wizard-items.initial .div {
  font-family: var(--body-strong-medium-font-family);
  font-size: var(--body-strong-medium-font-size);
  font-style: var(--body-strong-medium-font-style);
  font-weight: var(--body-strong-medium-font-weight);
  letter-spacing: var(--body-strong-medium-letter-spacing);
  line-height: var(--body-strong-medium-line-height);
}

.wizard-items.middle .div {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}
