.side-nav {
  align-items: flex-start;
  flex-direction: column;
  gap: var(--space-space-large);
  position: relative;
  width: 100%;
  
}

.logo {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: var(--space-size-small);
}
 
.side-nav .side-nav-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--space-space-2xsmall);
  position: relative;
  width: 100%;
  background-color: rgba(137, 43, 226, 0);
}
 
.side-nav .nav {

  align-self: stretch;
  display: inline-flex;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--space-space-3xsmall);
  position: relative;
  width: 100%;
}
 
.side-nav.is-collapse-false {
  display: flex;
  width: 100%;
}
 
.side-nav.is-collapse-true {
  display: inline-flex;
  background-color: red;
}
 