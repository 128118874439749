.alert {
  align-items: center;
  border-radius: var(--radius-global-radius-m);
  display: flex;
  gap: var(--space-space-2xsmall);
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: var(--space-size-2xlarge);
}

.alert .line {
  align-self: stretch;
  position: relative;
  width: 4px;
}

.alert-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--space-space-3xsmall);
  justify-content: center;
  padding: var(--space-space-2xsmall) var(--space-space-2xsmall) var(--space-space-2xsmall) 0px;
  position: relative;
}

.alert .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--space-space-3xsmall);
  position: relative;
  width: 100%;
}

.alert .text-wrapper {
  color: var(--colors-content-primary);
  font-family: var(--header-xsmall-font-family);
  font-size: var(--header-xsmall-font-size);
  font-style: var(--header-xsmall-font-style);
  font-weight: var(--header-xsmall-font-weight);
  letter-spacing: var(--header-xsmall-letter-spacing);
  line-height: var(--header-xsmall-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.alert-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.alert .div {
  color: var(--colors-content-secondary);
  flex: 1;
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
  position: relative;
}

.alert .mdi-information {
  height: var(--space-size-small) !important;
  position: relative !important;
  width: var(--space-size-small) !important;
}

.alert.positive {
  background-color: var(--colors-background-positive-surface);
}

.alert.warning {
  background-color: var(--colors-background-warning-surface);
}

.alert.informative {
  background-color: var(--colors-background-informative-surface);
}

.alert.neutral {
  background-color: var(--colors-background-neutral-surface);
}

.alert.negative {
  background-color: var(--colors-background-negative-surface);
}

.alert.positive .line {
  background-color: var(--colors-background-positive-strong);
}

.alert.warning .line {
  background-color: var(--colors-background-warning-strong);
}

.alert.informative .line {
  background-color: var(--colors-background-informative-strong);
}

.alert.neutral .line {
  background-color: var(--colors-background-neutral-strong);
}

.alert.negative .line {
  background-color: var(--colors-background-negative-strong);
}
