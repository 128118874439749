.cardrfptitle {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-color: #007bff;
  border-radius: 8px;
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  align-items: center;
  gap: var(--space-space-2xsmall);
  padding-bottom: var(--space-size-small);
}
.header > div {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
}
.drawer-title {
  padding: var(--space-size-large);
  width: auto;
}
.rfptitle {
  margin-left: var(--space-size-xsmall);
  font-family: var(--header-medium-font-family);
  font-weight: var(--header-medium-font-weight);
  color: var(--colors-content-primary);
  font-size: var(--header-medium-font-size);
  letter-spacing: var(--header-medium-letter-spacing);
  line-height: var(--header-medium-line-height);
  white-space: nowrap;
  font-style: var(--header-medium-font-style);
  font-feature-settings: 'clig' off, 'liga' off ;

}

.maincontent {
  color: var(  --colors-content-primary);
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}
.label {
  color: var(  --colors-content-tertiary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}
.rfptitle_client_ref{
  margin-left: var(--space-size-xsmall);
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  border: 1px solid var(--colors-border-neutral-subtle); 
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}
.rfptitle_strong_large{
  margin-left: 10px;
  color: var(  --colors-content-tertiary);
  font-family: var(--body-strong-large-font-family);
  font-size: var(--body-strong-large-font-size);
  font-style: var(--body-strong-large-font-style);
  font-weight: var(--body-strong-large-font-weight);
  letter-spacing: var(--body-strong-large-letter-spacing);
  line-height: var(--body-strong-large-line-height);
}