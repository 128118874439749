.side-nav-item {
  align-items: center;
  border-radius: var(--radius-global-radius-m);
  gap: var(--space-space-2xsmall);
  position: relative;
  display: flex;
  height: var(--space-space-2xlarge);
  width: 100%;
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--colors-action-neutral-normal);
}
 
.side-nav-item .label {
  flex: 1;
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  
}
 
.side-nav-item .custom-icon {
  height: var(--space-size-small) !important;
  position: relative !important;
  width: var(--space-size-small) !important;
}
 
.side-nav-item .counter-instance {
  flex: 0 0 auto !important;
}
 
.side-nav-item.active {
  background-color: var(--colors-action-ghost-brand-selected);
  color: var(--colors-action-inverted-normal);
  position: relative;
}

.side-nav-item.active.hover {
  background-color: var(--colors-action-ghost-brand-selected);
  color: var(--colors-action-inverted-normal);
  position: relative;
}
 
.side-nav-item.is-collapse-true {
  display: inline-flex;
  padding: var(--space-space-2xsmall);
}
 
.side-nav-item.hover {
  background-color: var(--colors-action-ghost-brand-hover);
}
 
.side-nav-item.default {
  background-color: var(--colors-action-ghost-brand-normal);
}
 
.side-nav-item.is-collapse-false {
  display: flex;
}
 
.side-nav-item.is-collapse-false.hover {
}
 
.side-nav-item.active .label {
  color: var(--colors-action-inverted-normal);
}
 
.side-nav-item.default .label {
  color: var(--colors-action-neutral-normal);
}
 
.side-nav-item.hover .label {
  color: var(--colors-action-neutral-normal);
}