.side-nav-item-user-info-container{
    padding: var(--space-space-small);
}

.side-nav-item-user-info {
    display: flex;
    align-items: center;
    padding: var(--space-space-2xsmall);
    background-color: var(--colors-action-neutral-subtle-normal);
    font-family: var(--body-normal-medium-font-family);
    border-radius: var(--radius-global-radius-m);
    gap: var(--space-space-2xsmall);
    width: 100%;
    box-sizing: border-box;
}

.user-info-acronym {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--space-size-large);
    height: var(--space-size-large);
    border-radius: 50%;
    /* makes the shape round */
    background: var(--colors-background-neutral-strong);
    margin-right: var(--space-space-2xsmall);
}

.side-nav-item-user-info-acronym {
    text-align: center;
    vertical-align: middle;
    font-family: var(--body-strong-small-font-family);
    font-size: var(--body-strong-small-font-size);
    font-style: var(--body-strong-small-font-style);
    font-weight: var(--body-strong-small-font-weight);
    letter-spacing: var(--body-strong-small-letter-spacing);
    line-height: var(--body-strong-small-line-height);
    color: var(--colors-content-inverted-primary);
}

.side-nav-item-user-info-user-info-details {
    display: flex;
    flex-direction: column;
}

.side-nav-item-user-info-user-name {
    font-family: var(--body-normal-medium-font-family);
    font-size: var(--body-normal-medium-font-size);
    font-style: var(--body-normal-medium-font-style);
    font-weight: var(--body-normal-medium-weight);
    letter-spacing: var(--body-normal-medium-letter-spacing);
    line-height: var(--body-normal-medium-line-height);
    color: var(--colors-content-primary);
}

.side-nav-item-user-info-user-role {
    font-family: var(--body-normal-small-font-family);
    font-size: var(--body-normal-small-font-size);
    font-style: var(--body-normal-small-font-style);
    font-weight: var(--body-normal-small-weight);
    letter-spacing: var(--body-normal-small-letter-spacing);
    line-height: var(--body-normal-small-line-height);
    color: var(--colors-content-secondary);
}

.side-nav-item-user-info-logout-button {
    align-items: center;
    justify-content: center; 
    display: flex;
    width: 100%;
    font-family: var(--body-normal-medium-font-family);
    font-size: var(--body-normal-medium-font-size);
    font-style: var(--body-normal-medium-font-style);
    font-weight: var(--body-normal-medium-weight);
    letter-spacing: var(--body-normal-medium-letter-spacing);
    line-height: var(--body-normal-medium-line-height);
    color: var(--colors-content-primary);
    margin-bottom: var(--space-size-large);
    margin-top: var(--space-size-xsmall);
}