.card-languages-label {
    color: var(--colors-content-primary);
    font-family: var(--body-normal-large-font-family);
    font-size: var(--body-normal-large-font-size);
    font-style: var(--body-normal-large-font-style);
    font-weight: var(--body-normal-large-font-weight);
    letter-spacing: var(--body-normal-large-letter-spacing);
    line-height: var(--body-normal-large-line-height);
}

.card-languages-row {
    display: grid;
    /*grid-template-columns: repeat(auto-fit, minmax(0, 1fr));*/
    grid-template-columns: 1fr auto 48px;
    justify-content: left;
    padding-top:  var(--space-space-small);
    padding-bottom:  var(--space-space-small);
}
.card-languages-row-2col {
    display: grid;
    grid-template-columns: 95% 1fr;
    justify-content: left;
    padding-top:  var(--space-space-small);
    padding-bottom:  var(--space-space-small);
}
.card-languages-row-inactive {
    display: grid;
    grid-template-columns: auto 70% ;
    justify-content: left;
    height: var(--space-space-large);
    vertical-align: middle;
}

.card-languages-flex-fix {
    display: flex;
    vertical-align: middle;
    box-sizing: border-box;
}
ul li {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: var(--colors-border-default);
    padding-top:  var(--space-space-xsmall);
    padding-bottom:  var(--space-space-xsmall);
}
ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0px;
}
ul li:first-child {
    padding-top: 0px;
}

.application-details .react-tabs__tab-list ul,
.application-details .react-tabs__tab-list li:last-child {
    padding-top:  var(--space-space-small);
    padding-bottom:  var(--space-space-small);
}
.application-details .react-tabs__tab-list ul,
.application-details .react-tabs__tab-list li:first-child  {
    padding-top:  var(--space-space-small);
    padding-bottom:  var(--space-space-small);
}

.card-languages-list{
    width: 100%;
    box-sizing: border-box;
}
.card-languages-left {
    padding-right: var(--space-space-small);
}
.card-languages-middle {
    width: 240px;
  padding-left: var(--space-space-small) ;
}
.card-languages-right {
   justify-self: right;
}
.card-languages-right-icon-bare {
    justify-self: center;
 }
.card-padding-fix {
    padding: 0px;
}
.card-row-std {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
}
.card-row-3 {
    display: grid;
    width: 100%;
    grid-template-columns: 33% 33% 33%;
}
.card-boxsize{
    box-sizing: border-box;
}