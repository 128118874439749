.tag {
  align-items: center;
  border: 1px solid;
  border-radius: var(--radius-global-radius-m);
  display: inline-flex;
  gap: var(--space-space-3xsmall);
  position: relative;
  height: var(--space-size-medium);
}

.tag .prefix {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--space-space-3xsmall);
  justify-content: center;
  position: relative;
}

.tag .text-wrapper {
  color: var(--colors-content-tertiary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tag .label {
  color: var(--colors-content-primary);
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tag .icon-button-instance {
  flex: 0 0 auto !important;
}

.tag .mdi-close {
  height: var(--space-size-medium) !important;
  position: relative !important;
  width: var(--space-size-medium) !important;
}

.tag.states-hover {
  background-color: var(--colors-action-neutral-subtle-hover);
  border-color: var(--colors-action-neutral-subtle-hover);
}

.tag.states-default {
  background-color: var(--colors-action-neutral-subtle-normal);
  border-color: var(--colors-border-default);
}

.tag.has-close-button-false {
  
  padding: var(--space-space-3xsmall) var(--space-space-2xsmall) var(--space-space-3xsmall) var(--space-space-2xsmall);
}

.tag.has-close-button-true {
  padding: var(--space-space-3xsmall) var(--space-space-3xsmall) var(--space-space-3xsmall) var(--space-space-2xsmall);
}

.tag-instance {
  margin-right: var(--space-space-2xsmall);
}