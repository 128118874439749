.button {
  all: unset;
  align-items: center;
  border-radius: var(--radius-global-radius-m);
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  cursor: pointer;
}

.button .class {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.button .class-2 {
  height: var(--space-size-small) !important;
  position: relative !important;
  width: var(--space-size-small) !important;
}

.button .container {
  position: relative;
}

.button .button-label {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button .text-wrapper {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.disabled {
  opacity: 0.35;
}

.button.has-before-icon-true {
  gap: var(--space-space-2xsmall);
}

.button.has-dropdown-indicator-false {
  gap: var(--space-space-2xsmall);
}

.button.ghost.large {
  height: var(--space-size-2xlarge);
}

.button.default.secondary {
  background-color: var(--colors-action-inverted-normal);
  border: 1px solid;
}

.button.default.medium-default {
  height: var(--space-size-xlarge);
}

.button.has-before-icon-false.has-dropdown-indicator-true {
  gap: var(--space-space-3xsmall);
}

.button.ghost.small {
  height: var(--space-size-large);
}

.button.ghost.medium-default {
  height: var(--space-size-xlarge);
}

.button.default.large {
  height: var(--space-size-2xlarge);
}

.button.tertiary.hover {
  background-color: var(--colors-action-neutral-subtle-hover);
}

.button.tertiary.disabled {
  background-color: var(--colors-action-neutral-subtle-normal);
}

.button.tertiary.normal {
  background-color: var(--colors-action-neutral-subtle-normal);
}

.button.default.small {
  height: var(--space-size-large);
}

.button.default.secondary.disabled {
  border-color: var(--colors-action-outline-normal);
}

.button.hover.default.secondary {
  box-shadow: var(--1);
}

.button.hover.ghost.primary {
  background-color: var(--colors-action-ghost-brand-hover);
}

.button.default.secondary.normal {
  border-color: var(--colors-action-outline-normal);
  box-shadow: var(--1);
}

.button.hover.default.primary {
  box-shadow: var(--1);
}

.button.default.primary.normal {
  box-shadow: var(--1);
}

.button.hover.ghost.secondary {
  background-color: var(--colors-action-ghost-brand-hover);
}

.button.positive.secondary.hover {
  border-color: var(--colors-action-positive-hover);
}

.button.hover.error.secondary {
  border-color: var(--colors-action-negative-hover);
}

.button.has-before-icon-false.has-dropdown-indicator-true.large.ghost {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.has-before-icon-true.has-dropdown-indicator-true.ghost.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-false.ghost.large.has-after-icon-true {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.has-before-icon-true.has-after-icon-true.ghost.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-false.default.large.has-after-icon-true {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.positive.default.primary.hover {
  background-color: var(--colors-action-positive-hover);
}

.button.has-before-icon-false.ghost.small.has-after-icon-true {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-xsmall);
}

.button.hover.default.primary.none {
  background-color: var(--colors-action-primary-hover);
}

.button.default.has-dropdown-indicator-true.has-before-icon-true.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.positive.default.primary.disabled {
  background-color: var(--colors-action-positive-normal);
}

.button.default.primary.none.disabled {
  background-color: var(--colors-action-primary-normal);
}

.button.has-before-icon-false.has-dropdown-indicator-true.medium-default.default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.has-before-icon-false.ghost.has-after-icon-true.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.positive.default.primary.normal {
  background-color: var(--colors-action-positive-normal);
}

.button.default.primary.none.normal {
  background-color: var(--colors-action-primary-normal);
}

.button.has-before-icon-true.has-after-icon-true.large.default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.hover.default.secondary.none {
  border-color: var(--colors-action-outline-hover);
}

.button.has-before-icon-false.has-dropdown-indicator-true.small.ghost {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-xsmall);
}

.button.large.has-before-icon-true.has-dropdown-indicator-true.ghost {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.default.error.primary.normal {
  background-color: var(--colors-action-negative-normal);
}

.button.has-before-icon-true.small.has-dropdown-indicator-true.default {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.button.hover.default.error.primary {
  background-color: var(--colors-action-negative-hover);
}

.button.large.has-before-icon-true.ghost.has-after-icon-true {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.default.has-after-icon-true.has-before-icon-true.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-false.has-dropdown-indicator-true.small.default {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-true.small.has-dropdown-indicator-true.ghost {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.button.has-before-icon-true.small.ghost.has-after-icon-true {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.button.has-before-icon-false.has-dropdown-indicator-true.ghost.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.has-before-icon-true.has-dropdown-indicator-true.large.default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-false.default.has-after-icon-true.medium-default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.has-before-icon-false.default.small.has-after-icon-true {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-xsmall);
}

.button.has-before-icon-false.has-dropdown-indicator-true.large.default {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-small);
}

.button.default.small.has-before-icon-true.has-after-icon-true {
  padding: 0px var(--space-space-2xsmall) 0px var(--space-space-2xsmall);
}

.button.default.error.primary.disabled {
  background-color: var(--colors-action-negative-normal);
}

.button.has-after-icon-false.has-before-icon-true.ghost.has-dropdown-indicator-false.large {
  padding: 0px var(--space-space-small) 0px var(--space-space-xsmall);
}

.button.has-after-icon-false.default.has-dropdown-indicator-false.has-before-icon-false.medium-default {
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
}

.button.has-after-icon-false.ghost.has-dropdown-indicator-false.has-before-icon-false.large {
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
}

.button.has-after-icon-false.ghost.has-dropdown-indicator-false.has-before-icon-false.small {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-after-icon-false.has-before-icon-true.default.has-dropdown-indicator-false.medium-default {
  padding: 0px var(--space-space-small) 0px var(--space-space-xsmall);
}

.button.has-after-icon-false.has-before-icon-true.default.has-dropdown-indicator-false.large {
  padding: 0px var(--space-space-small) 0px var(--space-space-xsmall);
}

.button.has-after-icon-false.has-before-icon-true.default.has-dropdown-indicator-false.small {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-2xsmall);
}

.button.has-after-icon-false.default.has-dropdown-indicator-false.has-before-icon-false.small {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-xsmall);
}

.button.has-after-icon-false.has-before-icon-true.ghost.has-dropdown-indicator-false.small {
  padding: 0px var(--space-space-xsmall) 0px var(--space-space-2xsmall);
}

.button.has-after-icon-false.ghost.has-dropdown-indicator-false.has-before-icon-false.medium-default {
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
}

.button.has-after-icon-false.default.has-dropdown-indicator-false.has-before-icon-false.large {
  padding: 0px var(--space-space-small) 0px var(--space-space-small);
}

.button.has-after-icon-false.has-before-icon-true.ghost.has-dropdown-indicator-false.medium-default {
  padding: 0px var(--space-space-small) 0px var(--space-space-xsmall);
}

.button.has-dropdown-indicator-true .container {
  align-items: center;
  color: var(--colors-action-positive-normal);
  display: inline-flex;
  flex: 0 0 auto;
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  gap: var(--space-space-3xsmall);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.has-dropdown-indicator-false .container {
  white-space: nowrap;
  width: fit-content;
}

.button.default.medium-default .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.bare.medium-default .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.ghost.small .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.has-dropdown-indicator-false.link .container {
  text-decoration: underline;
}

.button.ghost.medium-default .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.small.link .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.default.small .container {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.has-dropdown-indicator-false.bare.large .container {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
  margin-top: -1px;
}

.button.has-dropdown-indicator-false.default.large .container {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.has-dropdown-indicator-false.default.primary .container {
  color: var(--colors-action-inverted-normal);
}

.button.has-dropdown-indicator-false.bare.small .container {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.has-dropdown-indicator-false.tertiary.hover .container {
  color: var(--colors-action-neutral-hover);
}

.button.has-dropdown-indicator-false.link.large .container {
  font-family: var(--body-underline-large-font-family);
  font-size: var(--body-underline-large-font-size);
  font-style: var(--body-underline-large-font-style);
  font-weight: var(--body-underline-large-font-weight);
  letter-spacing: var(--body-underline-large-letter-spacing);
  line-height: var(--body-underline-large-line-height);
}

.button.has-dropdown-indicator-false.link.medium-default .container {
  font-family: var(--body-underline-medium-font-family);
  font-size: var(--body-underline-medium-font-size);
  font-style: var(--body-underline-medium-font-style);
  font-weight: var(--body-underline-medium-font-weight);
  letter-spacing: var(--body-underline-medium-letter-spacing);
  line-height: var(--body-underline-medium-line-height);
}

.button.has-dropdown-indicator-false.tertiary.disabled .container {
  color: var(--colors-action-neutral-normal);
}

.button.has-dropdown-indicator-false.tertiary.normal .container {
  color: var(--colors-action-neutral-normal);
}

.button.has-dropdown-indicator-false.ghost.large .container {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.has-dropdown-indicator-false.hover.error.secondary .container {
  color: var(--colors-action-negative-hover);
}

.button.positive.ghost.primary.normal .container {
  color: var(--colors-action-positive-normal);
}

.button.has-dropdown-indicator-false.error.secondary.normal .container {
  color: var(--colors-action-negative-normal);
}

.button.has-dropdown-indicator-false.secondary.none.normal .container {
  color: var(--colors-action-neutral-normal);
}

.button.has-dropdown-indicator-false.hover.secondary.none .container {
  color: var(--colors-action-neutral-hover);
}

.button.positive.bare.primary.normal .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.bare.secondary.disabled .container {
  color: var(--colors-action-positive-normal);
}

.button.has-dropdown-indicator-false.hover.primary.link .container {
  color: var(--colors-action-informative-hover);
}

.button.has-dropdown-indicator-false.secondary.none.disabled .container {
  color: var(--colors-action-neutral-normal);
}

.button.positive.default.secondary.normal .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.bare.primary.disabled .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.ghost.secondary.disabled .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.default.secondary.disabled .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.ghost.primary.disabled .container {
  color: var(--colors-action-positive-normal);
}

.button.positive.ghost.secondary.normal .container {
  color: var(--colors-action-positive-normal);
}

.button.has-dropdown-indicator-false.primary.link.normal .container {
  color: var(--colors-action-informative-normal);
}

.button.has-dropdown-indicator-false.disabled.error.secondary .container {
  color: var(--colors-action-negative-normal);
}

.button.positive.bare.secondary.normal .container {
  color: var(--colors-action-positive-normal);
}

.button.has-dropdown-indicator-false.primary.link.disabled .container {
  color: var(--colors-action-informative-normal);
}

.button.has-dropdown-indicator-false.positive.secondary.hover .container {
  color: var(--colors-action-positive-hover);
}

.button.disabled.ghost.primary.none.has-dropdown-indicator-false .container {
  color: var(--colors-action-primary-normal);
}

.button.hover.ghost.primary.none.has-dropdown-indicator-false .container {
  color: var(--colors-action-primary-hover);
}

.button.positive.secondary.link.normal.has-dropdown-indicator-false .container {
  color: var(--colors-action-informative-normal);
}

.button.error.disabled.bare.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-normal);
}

.button.ghost.primary.none.normal.has-dropdown-indicator-false .container {
  color: var(--colors-action-primary-normal);
}

.button.positive.hover.ghost.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-positive-hover);
}

.button.error.bare.primary.normal.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-normal);
}

.button.positive.secondary.disabled.link.has-dropdown-indicator-false .container {
  color: var(--colors-action-informative-normal);
}

.button.error.hover.bare.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-hover);
}

.button.error.hover.ghost.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-hover);
}

.button.disabled.bare.primary.none.has-dropdown-indicator-false .container {
  color: var(--colors-action-informative-normal);
}

.button.hover.bare.primary.none.has-dropdown-indicator-false .container {
  color: var(--colors-action-informative-hover);
}

.button.positive.hover.bare.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-positive-hover);
}

.button.error.ghost.primary.normal.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-normal);
}

.button.bare.primary.none.normal.has-dropdown-indicator-false .container {
  color: var(--colors-action-informative-normal);
}

.button.error.disabled.ghost.primary.has-dropdown-indicator-false .container {
  color: var(--colors-action-negative-normal);
}

.button.link .button-label {
  text-decoration: underline;
}

.button.bare.small .button-label {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.ghost.large .button-label {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.default.medium-default .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.default.primary .button-label {
  color: var(--colors-action-inverted-normal);
}

.button.bare.medium-default .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.link.large .button-label {
  font-family: var(--body-underline-large-font-family);
  font-size: var(--body-underline-large-font-size);
  font-style: var(--body-underline-large-font-style);
  font-weight: var(--body-underline-large-font-weight);
  letter-spacing: var(--body-underline-large-letter-spacing);
  line-height: var(--body-underline-large-line-height);
}

.button.ghost.small .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.bare.large .button-label {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.link.medium-default .button-label {
  font-family: var(--body-underline-medium-font-family);
  font-size: var(--body-underline-medium-font-size);
  font-style: var(--body-underline-medium-font-style);
  font-weight: var(--body-underline-medium-font-weight);
  letter-spacing: var(--body-underline-medium-letter-spacing);
  line-height: var(--body-underline-medium-line-height);
}

.button.ghost.medium-default .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.default.large .button-label {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.tertiary.hover .button-label {
  color: var(--colors-action-neutral-hover);
}

.button.small.link .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.tertiary.disabled .button-label {
  color: var(--colors-action-neutral-normal);
}

.button.tertiary.normal .button-label {
  color: var(--colors-action-neutral-normal);
}

.button.default.small .button-label {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.secondary.none.normal .button-label {
  color: var(--colors-action-neutral-normal);
}

.button.primary.link.normal .button-label {
  color: var(--colors-action-informative-normal);
}

.button.hover.primary.link .button-label {
  color: var(--colors-action-informative-hover);
}

.button.error.secondary.normal .button-label {
  color: var(--colors-action-negative-normal);
}

.button.hover.secondary.none .button-label {
  color: var(--colors-action-neutral-hover);
}

.button.secondary.none.disabled .button-label {
  color: var(--colors-action-neutral-normal);
}

.button.disabled.error.secondary .button-label {
  color: var(--colors-action-negative-normal);
}

.button.primary.link.disabled .button-label {
  color: var(--colors-action-informative-normal);
}

.button.positive.secondary.hover .button-label {
  color: var(--colors-action-positive-hover);
}

.button.hover.error.secondary .button-label {
  color: var(--colors-action-negative-hover);
}

.button.bare.primary.none.normal .button-label {
  color: var(--colors-action-informative-normal);
}

.button.positive.ghost.primary.normal .button-label {
  color: var(--colors-action-positive-normal);
}

.button.bare.error.primary.disabled .button-label {
  color: var(--colors-action-negative-normal);
}

.button.positive.bare.primary.normal .button-label {
  color: var(--colors-action-positive-normal);
}

.button.positive.bare.secondary.disabled .button-label {
  color: var(--colors-action-positive-normal);
}

.button.hover.ghost.primary.none .button-label {
  color: var(--colors-action-primary-hover);
}

.button.positive.secondary.link.normal .button-label {
  color: var(--colors-action-informative-normal);
}

.button.positive.default.secondary.normal .button-label {
  color: var(--colors-action-positive-normal);
}

.button.ghost.primary.none.normal .button-label {
  color: var(--colors-action-primary-normal);
}

.button.hover.bare.error.primary .button-label {
  color: var(--colors-action-negative-hover);
}

.button.positive.secondary.link.disabled .button-label {
  color: var(--colors-action-informative-normal);
}

.button.positive.bare.primary.disabled .button-label {
  color: var(--colors-action-positive-normal);
}

.button.bare.error.primary.normal .button-label {
  color: var(--colors-action-negative-normal);
}

.button.positive.ghost.secondary.disabled .button-label {
  color: var(--colors-action-positive-normal);
}

.button.ghost.primary.none.disabled .button-label {
  color: var(--colors-action-primary-normal);
}

.button.hover.bare.primary.none .button-label {
  color: var(--colors-action-informative-hover);
}

.button.positive.default.secondary.disabled .button-label {
  color: var(--colors-action-positive-normal);
}

.button.ghost.error.primary.disabled .button-label {
  color: var(--colors-action-negative-normal);
}

.button.positive.ghost.primary.disabled .button-label {
  color: var(--colors-action-positive-normal);
}

.button.positive.ghost.secondary.normal .button-label {
  color: var(--colors-action-positive-normal);
}

.button.positive.bare.primary.hover .button-label {
  color: var(--colors-action-positive-hover);
}

.button.ghost.error.primary.normal .button-label {
  color: var(--colors-action-negative-normal);
}

.button.positive.bare.secondary.normal .button-label {
  color: var(--colors-action-positive-normal);
}

.button.hover.ghost.error.primary .button-label {
  color: var(--colors-action-negative-hover);
}

.button.positive.ghost.primary.hover .button-label {
  color: var(--colors-action-positive-hover);
}

.button.bare.primary.none.disabled .button-label {
  color: var(--colors-action-informative-normal);
}

.button.link .text-wrapper {
  text-decoration: underline;
}

.button.ghost.large .text-wrapper {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.default.medium-default .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.default.primary .text-wrapper {
  color: var(--colors-action-inverted-normal);
}

.button.bare.medium-default .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.link.large .text-wrapper {
  font-family: var(--body-underline-large-font-family);
  font-size: var(--body-underline-large-font-size);
  font-style: var(--body-underline-large-font-style);
  font-weight: var(--body-underline-large-font-weight);
  letter-spacing: var(--body-underline-large-letter-spacing);
  line-height: var(--body-underline-large-line-height);
}

.button.ghost.small .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.bare.large .text-wrapper {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.link.medium-default .text-wrapper {
  font-family: var(--body-underline-medium-font-family);
  font-size: var(--body-underline-medium-font-size);
  font-style: var(--body-underline-medium-font-style);
  font-weight: var(--body-underline-medium-font-weight);
  letter-spacing: var(--body-underline-medium-letter-spacing);
  line-height: var(--body-underline-medium-line-height);
}

.button.ghost.medium-default .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.default.large .text-wrapper {
  font-family: var(--body-normal-large-font-family);
  font-size: var(--body-normal-large-font-size);
  font-style: var(--body-normal-large-font-style);
  font-weight: var(--body-normal-large-font-weight);
  letter-spacing: var(--body-normal-large-letter-spacing);
  line-height: var(--body-normal-large-line-height);
}

.button.tertiary.hover .text-wrapper {
  color: var(--colors-action-neutral-hover);
}

.button.tertiary.disabled .text-wrapper {
  color: var(--colors-action-neutral-normal);
}

.button.tertiary.normal .text-wrapper {
  color: var(--colors-action-neutral-normal);
}

.button.default.small .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.secondary.none.normal .text-wrapper {
  color: var(--colors-action-neutral-normal);
}

.button.primary.link.normal .text-wrapper {
  color: var(--colors-action-informative-normal);
}

.button.hover.primary.link .text-wrapper {
  color: var(--colors-action-informative-hover);
}

.button.bare.small.has-after-icon-false .text-wrapper {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.positive.secondary.normal .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.has-before-icon-true.small.bare .text-wrapper {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.hover.bare.small .text-wrapper {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.error.secondary.normal .text-wrapper {
  color: var(--colors-action-negative-normal);
}

.button.hover.secondary.none .text-wrapper {
  color: var(--colors-action-neutral-hover);
}

.button.secondary.none.disabled .text-wrapper {
  color: var(--colors-action-neutral-normal);
}

.button.bare.small.disabled .text-wrapper {
  font-family: var(--body-normal-small-font-family);
  font-size: var(--body-normal-small-font-size);
  font-style: var(--body-normal-small-font-style);
  font-weight: var(--body-normal-small-font-weight);
  letter-spacing: var(--body-normal-small-letter-spacing);
  line-height: var(--body-normal-small-line-height);
}

.button.positive.secondary.disabled .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.disabled.error.secondary .text-wrapper {
  color: var(--colors-action-negative-normal);
}

.button.primary.link.disabled .text-wrapper {
  color: var(--colors-action-informative-normal);
}

.button.positive.secondary.hover .text-wrapper {
  color: var(--colors-action-positive-hover);
}

.button.hover.error.secondary .text-wrapper {
  color: var(--colors-action-negative-hover);
}

.button.hover.ghost.primary.none .text-wrapper {
  color: var(--colors-action-primary-hover);
}

.button.positive.bare.primary.disabled .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.ghost.primary.none.disabled .text-wrapper {
  color: var(--colors-action-primary-normal);
}

.button.hover.bare.primary.none .text-wrapper {
  color: var(--colors-action-informative-hover);
}

.button.bare.primary.none.disabled .text-wrapper {
  color: var(--colors-action-informative-normal);
}

.button.bare.error.primary.normal .text-wrapper {
  color: var(--colors-action-negative-normal);
}

.button.positive.bare.primary.hover .text-wrapper {
  color: var(--colors-action-positive-hover);
}

.button.bare.primary.none.normal .text-wrapper {
  color: var(--colors-action-informative-normal);
}

.button.bare.error.primary.disabled .text-wrapper {
  color: var(--colors-action-negative-normal);
}

.button.positive.bare.primary.normal .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.ghost.primary.none.normal .text-wrapper {
  color: var(--colors-action-primary-normal);
}

.button.hover.ghost.error.primary .text-wrapper {
  color: var(--colors-action-negative-hover);
}

.button.positive.ghost.primary.hover .text-wrapper {
  color: var(--colors-action-positive-hover);
}

.button.positive.ghost.primary.normal .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.has-before-icon-false.small.has-after-icon-true.normal .text-wrapper {
  font-family: var(--body-normal-medium-font-family);
  font-size: var(--body-normal-medium-font-size);
  font-style: var(--body-normal-medium-font-style);
  font-weight: var(--body-normal-medium-font-weight);
  letter-spacing: var(--body-normal-medium-letter-spacing);
  line-height: var(--body-normal-medium-line-height);
}

.button.hover.bare.error.primary .text-wrapper {
  color: var(--colors-action-negative-hover);
}

.button.ghost.error.primary.disabled .text-wrapper {
  color: var(--colors-action-negative-normal);
}

.button.positive.ghost.primary.disabled .text-wrapper {
  color: var(--colors-action-positive-normal);
}

.button.ghost.error.primary.normal .text-wrapper {
  color: var(--colors-action-negative-normal);
}

