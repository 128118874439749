.icon-button {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.icon-button .class {
  height: var(--space-size-small) !important;
  position: relative !important;
  width: var(--space-size-small) !important;
}

.icon-button .dropdown-indicator {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.icon-button.disabled {
  opacity: 0.35;
}

.icon-button.default {
  background-color: var(--colors-action-inverted-normal);
  border: 1px solid;
  box-shadow: var(--1);
  display: flex;
}

.icon-button.outline {
  border: 1px solid;
  display: flex;
}

.icon-button.bare {
  display: flex;
  border: none;
  border: 1px solid transparent;
  align-self: center;
  vertical-align: middle ;
}

.icon-button.has-dropdown-indicator-true {
  gap: var(--space-space-3xsmall);
}

.icon-button.ghost {
  display: flex;
}

.icon-button.rounded-true {
  border-radius: var(--radius-global-radius-round);
}

.icon-button.rounded-false {
  border-radius: var(--radius-global-radius-m);
}

.icon-button.hover.default {
  border-color: var(--colors-action-outline-hover);
}

.icon-button.ghost.large {
  height: var(--space-size-2xlarge);
}

.icon-button.outline.normal {
  border-color: var(--colors-action-outline-normal);
}

.icon-button.default.disabled {
  border-color: var(--colors-action-outline-normal);
}

.icon-button.outline.large {
  height: var(--space-size-2xlarge);
}

.icon-button.default.medium-default {
  height: var(--space-size-xlarge);
}

.icon-button.ghost.small {
  height: var(--space-size-large);
}

.icon-button.outline.hover {
  border-color: var(--colors-action-outline-hover);
}

.icon-button.ghost.medium-default {
  height: var(--space-size-xlarge);
}

.icon-button.default.large {
  height: var(--space-size-2xlarge);
}

.icon-button.outline.disabled {
  border-color: var(--colors-action-outline-normal);
}

.icon-button.default.normal {
  border-color: var(--colors-action-outline-normal);
}

.icon-button.hover.ghost {
  background-color: var(--colors-action-ghost-brand-hover);
}

.icon-button.outline.small {
  height: var(--space-size-large);
}

.icon-button.outline.medium-default {
  height: var(--space-size-xlarge);
}

.icon-button.default.small {
  height: var(--space-size-large);
}

.icon-button.outline.has-dropdown-indicator-true.medium-default {
  width: var(--space-size-4xlarge);
}

.icon-button.has-dropdown-indicator-false.small.outline {
  width: var(--space-size-large);
}

.icon-button.has-dropdown-indicator-false.default.large {
  width: var(--space-size-2xlarge);
}

.icon-button.has-dropdown-indicator-false.outline.large {
  width: var(--space-size-2xlarge);
}

.icon-button.has-dropdown-indicator-false.default.medium-default {
  width: var(--space-size-xlarge);
}

.icon-button.has-dropdown-indicator-true.large.default {
  width: var(--space-size-5xlarge);
}

.icon-button.has-dropdown-indicator-false.default.small {
  width: var(--space-size-large);
}

.icon-button.has-dropdown-indicator-false.outline.medium-default {
  width: var(--space-size-xlarge);
}

.icon-button.outline.has-dropdown-indicator-true.small {
  width: var(--space-size-3xlarge);
}

.icon-button.has-dropdown-indicator-true.small.default {
  width: var(--space-size-3xlarge);
}

.icon-button.has-dropdown-indicator-true.ghost.medium-default {
  width: var(--space-size-4xlarge);
}

.icon-button.outline.has-dropdown-indicator-true.large {
  width: var(--space-size-5xlarge);
}

.icon-button.has-dropdown-indicator-false.ghost.medium-default {
  width: var(--space-size-xlarge);
}

.icon-button.has-dropdown-indicator-false.ghost.large {
  width: var(--space-size-2xlarge);
}

.icon-button.has-dropdown-indicator-true.medium-default.default {
  width: var(--space-size-4xlarge);
}

.icon-button.large.has-dropdown-indicator-true.ghost {
  width: var(--space-size-5xlarge);
}

.icon-button.has-dropdown-indicator-false.ghost.small {
  width: var(--space-size-large);
}

.icon-button.has-dropdown-indicator-true.small.ghost {
  width: var(--space-size-3xlarge);
}
